/* The stiCountToggle - the container */
.stiCountToggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.stiCountToggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.stiCountToggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 34px;
  width: 54px;
  height: 24px;
  outline: 1px solid #cccccc;
}

/* Before sliding (the round circle inside the slider) */
.stiCountToggle .slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: #cccccc;
  transition: 0.4s;
  border-radius: 50%;
}

/* When the checkbox is checked */
.stiCountToggle input:checked + .slider {
  background-color: white;
  outline: 1px solid #31bdbf;
}

/* Show tick icon when checked */
.stiCountToggle input:checked + .slider:after {
  opacity: 1;
}

/* Move the slider when checked */
.stiCountToggle input:checked + .slider:before {
  transform: translateX(26px);
  background-color: #31bdbf !important;
}

/* Style for the unchecked state (gray background for slider button) */
.stiCountToggle .slider-unchecked::before {
  background-color: #cccccc;
}
