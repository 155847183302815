.b-color {
    background-color: #30bdbf !important;
}

.addressform-style {
    background-color: #30bdbf;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width:1025px) {
    .input-style {
        width: 100% !important;
    }
}

.opt-style {
    font-size: 19px;
    letter-spacing: 0.32px;
}

/* scroll to bottom */
.scroll-element{
    width: 70px;
    height: 70px;
    background-color: #FFF;
    border-radius: 50px;
    position: fixed;
    bottom: 25px;
    right: 35px;
    display: flex;
}

.scroll-element .scroll-arrow{
    margin: auto;
    cursor: pointer;
}

.footer-positon{
    position: absolute !important;
}
/* scroll to bottom */
@media (max-width: 580px) {
    .scroll-element{
        display: none !important;
    }
}