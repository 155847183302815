.confirmaddress-style {
    background-color: #30bdbf;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
}

.mar-p {
    margin-top: 20px;
}

.h-textbox {
    font-family: museosans;
    height: 180px !important;
    font-weight: 600;
}

.h-co {
    height: 80px !important
}