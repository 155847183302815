.order-kit-dropdown__control {
  height: 43px;
  border-radius: 50px !important;
  border-color: transparent !important;
  width: 311px !important;
  position: absolute !important;
  right: 0px !important;
  bottom: 14px !important;
}
.order-kit-dropdown__input-container {
  height: 40px;
}
.order-kit-dropdown__control--is-focused {
  border: none;
  box-shadow: none;
  border-width: 0px;
}
.order-kit-dropdown__value-container {
  padding-left: 20px !important;
}
/* .order-kit-dropdown__placeholder{
      padding-left: 15px;
  } */
.order-kit-dropdown__menu {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
  margin-top: -1px !important;
  max-width: 311px;
  position: absolute !important;
  right: 0px !important;
  bottom: 1px !important;
}
.order-kit-dropdown__option {
  padding: 15px 26px !important;
  font-weight: 600;
  cursor: pointer;
}

.order-kit-dropdown__option.order-kit-dropdown__option--is-focused {
  background-color: #f7be00;
}
.order-kit-dropdown__option.order-kit-dropdown__option--is-focused:hover {
  background-color: #f7be00 !important;
}
.order-kit-dropdown__option:hover {
  background-color: rgba(247, 190, 0, 0.1);
}
.order-kit-dropdown__option:active {
  background-color: #f7be00 !important;
}
.order-kit-dropdown__indicators {
  width: 100px;
}
.order-kit-dropdown__dropdown-indicator {
  display: none !important;
}
.pos-arrow-order {
  top: 16px !important;
  left: 9px !important;
  width: 20px;
}
.arrow-icon-order {
  height: 43px !important;
  width: 43px !important;
  bottom: 14px !important;
}
.css-1pahdxg-control {
  background-color: white !important;
}

/* search bar */

.search-blk {
  position: relative;
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 0px;
  max-width: 630px;
}

.search-blk .login-radius {
  border-radius: 10px;
  max-width: 500px;
  height: 50px !important;
  margin-top: 0 !important;
  padding-left: 40px;
  font-size: 18px !important;
}

.search-blk .login-radius::placeholder {
  font-size: 14px !important;
  color: grey !important;
}

.search-blk .btn {
  padding: 8px 25px !important;
  height: 52px !important;
  border-radius: 10px !important;
  margin-left: 20px;
}

.search-blk {
  outline: none !important;
}

.search-blk .search-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 17px;
  left: 10px;
}

.search-blk .close-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 22px;
  right: 150px;
  cursor: pointer;
}

.search-kit-btn {
  padding: 1px 7px 1px 15px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #652f91;
  border: 1px solid #652f91;
  border-radius: 8px;
  transition: all 0.1s linear;
  cursor: default !important;
}

.search-kit-btn span:nth-child(1) {
  margin-right: 8px;
}

.search-kit-btn span:nth-child(2) {
  margin-top: -2px;
  font-size: 14px;
  cursor: pointer !important;
}

.reduce-mar {
  margin-bottom: 0px !important;
}
