.title-color{
    color: #fff !important;
}

.card-style{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-size: 16px;
}

.card-border{
    border: 3px solid #652F91;
}

.sec-sub-desc{
    color: #fff;
    /* font-weight: 700; */
}

.sec-sub-desc p {
    font-size: 16px;
}

.sec-sub-desc.dark{
    color: black !important;
}

.consent-btn-bg-color{
    background-color: #fff !important;
}

.zig-zag-pattern.color-yellow{
    background-image: url('../../assets/images/signposting-arrow-yellow.svg');
}

.zig-zag-pattern.bg-yellow{
    background-color: #F8C415;
}

.section-desc-2-color{
    color: #fff !important;
}

.pattern-placement .top-right-havingsex{
    position: absolute;
    top: -48px;
    right: -40px;
}

@media (max-width:370px) {
    .mw-sexual-activity .top-right-havingsex{
        top: -136px;
    }
    .mw-sexual-activity .dark{
        width: 100px;
    }
}
.pattern-placement .top-left-havingsex{
    position: absolute;
    top: -38px;
    left: -15px;
}

.contr-btn-pad{
    padding: 13px 17px !important;
}

.sexual-link{
    color:#F8C415;
    text-decoration: underline;
}

.sexual-link:hover{
    color:#F8C415;
}
.sexual-link:focus{
    color:#F8C415 !important;
}

.desc-abs{
    position: absolute;
    top: 6px;
    left: 80px;
}


.person-image-pos{
    position:absolute;
    right: 0;
    top:-33px
}

.font-size-menu{
    font-size: 16px;
}

@media (max-width:768px){
    .margin-head{
        margin-top: 40px;
    }
}


.person2-image-pos{
    position: absolute;
    right: -26px;
    bottom: -144px;
}

.pad-for-sub{
    padding: 0px 0px 0px 0px ;
}

.pos-relative{
    position: relative;
}

.text-color-btn{
    color: black !important;
}

.triple-btn, .triple-btn:focus {
    background-color: #c7bfbf !important;
    color: #4c4545 !important;
}

.answer p {
    font-size: 16px;
}

.image-align {
    text-align: -webkit-right;
    position: relative;
    top: -39px;
}

@media (max-width:500px) {
   .image-align{
    top:-14px !important;
   } 
}

.support-section span{
    text-decoration: underline;
}

.link-section{
    padding-top: 0 !important;
}

@media (min-width:1025px) {
    .link-section{
        padding-left: 0 !important;
    }
}

.yellow-section h1, .yellow-section p{
    color: black;
}

.yellow-section p{
    font-weight: 700;}

@media  (max-width:473px) {
    .space-top{
        width: 100%;
        height: 10px;
    } 
}


@media (max-width: 768px){
.container-para {
    padding:0px 0px 25px 0px !important;
}
}

.sub-para{
    font-weight: 500;
}


@media (min-width: 768px){
    .sti-page-blk {
        padding: 97px !important; 
    }}
    
    
    @media (min-width: 320px) and (max-width: 343px){
        .pink-blk img{
            margin-top: -95px;
        }
    }
    @media (min-width: 344px) and (max-width: 393px){
        .pink-blk img{
            margin-top: -67px;
        }
    }
    
    
    @media (min-width: 394px) and (max-width: 448px){
        .pink-blk img{
            margin-top: -54px;
        }
    }
    
    @media (min-width: 449px) and (max-width: 510px){
        .pink-blk img{
            margin-top: -26px;
        }
    }
    
    @media (min-width:  511px) and (max-width: 547px){
        .pink-blk img{
            margin-top: -48px;
        }
    }
    
    @media (max-width: 768px){
    .pink-blk .container-para{
        padding-top: 25px !important;
    }
    
    .pink-blk .pink-btn-class{
        margin-top: 24px;
    }
}