.hepatitis-style {
  background-color: #582e7a;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hepatitis-title {
  /* font-size: 28px !important; */
  padding-bottom: 20px !important;
  padding-top: 0 !important;
}

.hepatitis-subtitle {
  /* font-size: 22px !important; */
  padding-bottom: 10px !important;
  padding-top: 0 !important;
  line-height: 24px;
}

.hepatitis-title {
  /* font-size: 20px !important; */
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  line-height: 30px !important;
}
.hepatitis-margin {
  padding-top: 20px !important;
}
.hepatitis-content-blk ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}

.hepatitis-content-blk ul>li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}

.vertical-height {
  min-height: 100vh !important;
}

.swab-text {
  font-size: 20px !important;
  font-weight: 400;
}

.swab-note {
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.hepatitis-title-content {
  /* font-size: 24px !important; */
  font-weight: 400 !important;
  padding-top: 0 !important;
}

.bold-word-text {
  font-weight: 600 !important;
}

.swab-note-blk {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 15px;
}

.clear-btn-blk {
  font-size: 18px;
  border: 1px solid #FFF;
  padding: 10px 20px;
  border-radius: 25px;
}

.Calm-btn-blk {
  border: 1px solid #244472 !important;
}

.highlight-text {
  font-weight: bold;
  font-size: 17px;
}