/* Dialog */
dialog {
  @apply fixed left-0 top-0 bottom-0 right-0 m-auto;
}

dialog.new-popup-card {
  @apply mx-auto rounded-lg shadow-lg;
  background-color: rgb(255 255 255);
  /* max-width: 660px; */
  max-width: 720px;
  width: 90%;
}
.new-popup-header h4 {
  @apply font-bold;
  color: rgb(49 49 49);
}
/* .new-popup-header h6 {
  @apply px-10;
} */
@media (max-width: 576px) {
  dialog .new-popup-card {
    text-align: left;
  }
}
dialog .new-popup-card-content {
  @apply mx-auto pt-4;
  max-width: 1300px;
}
dialog .new-popup-card-close {
  @apply absolute top-6 right-6 rounded-full
}
dialog .new-popup-card-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
dialog .new-popup-card-close svg {
  @apply mx-auto;
  color: rgb(49 49 49);
}
dialog .new-popup-card.md {
  max-width: 1300px;
  width: 90%;
}
dialog.new-popup-card.lg {
  max-width: 828px;
  width: 90%;
}
dialog.new-popup-card.xlg {
  max-width: 1300px;
  width: 90%;
}
dialog .new-popup-card.xlg .new-popup-body {
  max-height: calc(100vh - 285px);
}
.modal-logo-blk {
  @apply flex justify-center;
}
.modal-logo-blk svg {
  width: 120px;
}

