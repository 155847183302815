.confirmemail-style {
  background-color: #30bdbf;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  display: flex;
  flex-direction: column;
}

.my_info_popup {
  display: flex;
  flex-direction: column;
}

.invalid-date-t {
  top: 46% !important;
}

.thankyou-style {
  background-color: #99c355;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.whatnext-style {
  background-color: #99c355;
  color: white;
  min-height: 100vh;
  font-family: 'AlternateGothic', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.under16-style {
  background-color: #d35296;
  color: white;
  min-height: 100vh;
  font-family: 'AlternateGothic', sans-serif;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.l-height {
  line-height: 25px !important;
}

.not-found-paragraph {
  font-size: 48px;
  font-family: 'AlternateGothic', sans-serif;
}

.notfound-style {
  min-height: 100vh !important;
}

.footer-btn-notfound {
  text-align: center;
}

.not-found-style {
  font-size: 16px !important;
}

.not-found-header {
  text-align: center;
}

@media (max-width: 768px) {
  .l-space {
    line-height: 25px !important;
  }
}

.accordian-under16 .plus-i {
  margin-top: 9px !important;
}

.accordian-under16 .pad-te {
  padding-top: 0 !important;
}
.kit-block .reject-pop{
  max-width: 350px !important;
}
.kit-block .model-bor{
  border-radius: 10px !important;
  padding: 24px;
}
.kit-modal-header{
  display: inline-flex;
  justify-content: flex-end;
  cursor: pointer;
  align-self: flex-end;
}
.kit-block .kit-modal-content{
  margin-bottom: 0 !important;
  padding: 20px 0 !important;
}
.kit-block .modal-body{
  padding: 0 !important;
}