.vaginalsex-style {
    background-color: #D35296;
    color: white;
    min-height: calc(100vh - 75px);
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.brookclinic-style {
    background-color: #D35296;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
}

.fo-ma {
    margin-top: 100px;
}

.btn-wi {
    width: 280px;
}

.body-content {
    font-family: museosans;
    font-size: 16px;
    margin-top: 29px;
    letter-spacing: 0.32px;
}

.li-color {
    color: yellow;
    overflow-wrap: break-word;
    text-decoration: underline;
    font-weight: 600;
}

.li-color:hover {
    color: yellow !important;
}

.btn-sex {
    padding-top: 41px;
    text-align: center;
}

.te-col:focus{
color: white !important;
}