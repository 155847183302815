.userdetailsconsent-style {
    background-color: #D35296;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 30px; */
}

.big-color {
    background-color: #30BDBF !important;
}

.f-size {
    font-size: 19px;
}

.f-size:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: 0px !important;
}

.d-size {
    font-size: 19px;
    font-family: museosans !important;
    font-weight: normal !important;
}

.usernoteligible-style {
    background-color: #D35296;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-check-input:checked[type=checkbox] {
    background-image: url('../assets/images/Orange_Tick.svg');
    background-size: 24px;
    border: 1px solid #bfbfbf !important;
}

.form-check-input:checked {
    background-color: white !important;
}