.h-div {
  position: relative;
}

.top-loader {
  width: 10%;
  border-bottom: 5px solid #f9c40c;
}

.fulllayout-height {
  height: 70px;
  padding: 16px;
}

.welcome-style {
  background-color: #582e7a;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-header-txt {
  font-size: 29px;
  font-family: 'AlternateGothic', sans-serif;
}

@media (min-width: 1025px) {
  .speaker-i {
    left: 216px !important;
    top: 3px !important;
  }
}

.Dark-global {
  background-color: #221e22 !important;
}

.Dark-skin-color {
  background-color: #221e22 !important;
}

.Dark-loader-global {
  border-bottom: 5px solid #b50a7f !important;
}

.Dark-btn-global {
  background-color: #4d4747 !important;
  border: none !important;
}

.Calm-skin-color {
  background-color: #ebe2e0 !important;
}

.Calm-btn-global {
  background-color: #2379a4 !important;
}

.Calm-loader-global {
  border-bottom: 5px solid #b50a7f !important;
}

.Calm-txt-global {
  color: #244472 !important;
}

.Calm-global {
  background-color: #ebe2e0 !important;
}

.no-background-border {
  background: none;
  border: none;
}

.High-contrast-loader-global {
  border-bottom: 5px solid #f9c40c !important;
}

.High-contrast-global {
  background-color: #212035 !important;
}

.High-contrast-skin-color {
  background-color: #212035 !important;
}

.Dark-btn-global:hover,
.Dark-btn-global:focus,
.Dark-btn-global.selected {
  color: #221e22;
  background-color: #8cc63f !important;
  border: none !important;
}

.postcodeBarrier-panel {
  background-color: #d96aa4 !important;
  border-color: #d96aa4 !important;
}

.personelInfo-panel {
  background-color: rgba(178, 205, 206, 0.5) !important;
}

.Calm-panel {
  color: #244472 !important;
  background-color: rgba(210, 198, 195, 0.5) !important;
}

.Dark-panel {
  background-color: rgba(55, 65, 81, 0.5) !important;
}

.Regular-panel {
  background-color: rgba(129, 98, 155, 0.5) !important;
}
.whatnext-style-accordion-panel{
  background-color: rgba(137, 170, 97, 0.5) !important;
}
.High-contrast-panel {
  background-color: rgb(120 118 150 / 10%) !important;
}

.Calm-btn-global:hover,
.Calm-btn-global:focus,
.Calm-btn-global:active,
.Calm-btn-global.selected {
  color: white !important;
  background-color: #2379a4 !important;
  border: none !important;
}

.Regular-btn-global.selected {
  color: #582e7a !important;
  background-color: #f3eeed !important;
  border: none !important;
}

.Regular-skin-color {
  background-color: #582e7a !important;
}

.Calm-btn-global {
  color: #1f406f !important;
  background-color: #f3eeed !important;
  border: none !important;
}

.Calm-start-btn-global,
.Calm-start-btn-global:hover {
  color: white;
  background-color: #935daa !important;
  border: none !important;
}

.High-contrast-btn-global {
  color: white;
  background-color: #454556 !important;
  border: none !important;
}

.High-contrast-btn-global:hover,
.High-contrast-btn-global:focus,
.High-contrast-btn-global.selected {
  color: white;
  background-color: #b50a7f !important;
  border: none !important;
}

.High-contrast-start-btn-global {
  color: #0b0b0b;
  background-color: #ef9505 !important;
  border: none !important;
}

.background-none {
  background-color: white !important;
}

.b2-style {
  padding: 10px 0px;
  font-size: 15px;
  font-family: museosans;
}

.new-css {
  display: flex;
  justify-content: space-between;
}
.btn-list {
  @apply flex flex-wrap gap-x-3;
}

.b1-style {
  letter-spacing: 0.62px;
  padding: 50px 0px 10px 0px;
  font-size: 31px;
  font-family: museosans;
  line-height: 40px;
}

.b3-style {
  padding-top: 30px;
}

.b4-style {
  font-size: 17px;
  font-family: museosans;
  padding: 10px 0px;
  letter-spacing: 0.32px;
}

.btn-style {
  padding-top: 14px;
  position: relative;
}

@media (min-width: 1025px) {
  .next-btn-style {
    top: -64px;
    right: -240px;
  }
}

.btn-style-footer {
  padding-top: 20px;
  float: right;
}

.btn-main {
  /* width: 101px; */
  padding: 0px 35px;
  height: 50px;
  border-radius: 30px;
  font-size: 18px;
  font-family: museosans;
}

.btn-r {
  color: #582e7a;
  background-color: #81629b;
  width: 101px;
  height: 50px;
  border-radius: 30px;
  font-size: 14px;
  font-family: museosans;
}

.btn-d {
  background-color: #81629b;
  border: none;
  padding: 0px 35px;
  color: white;
  height: 50px;
  border-radius: 30px;
  font-size: 14px;
  font-family: museosans;
}

.btn-pos {
  position: absolute;
  left: 115px;
  padding: 0px 25px 0px 25px;
}

.btn-posi {
  position: absolute;
  left: 115px;
}

.btn-pos-only {
  position: absolute;
  left: 130px;
}

.speaker-i {
  position: absolute;
  right: 0;
  top: 0px;
}
