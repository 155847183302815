.sub-section {
    @apply w-full
}


.sub-section div {
    @apply cursor-pointer
}

.sub-option {
    display: flex;
    align-items: center;
}

.showColour-1 .react-select__control {
    border-color: #652f91 !important;
}

.showColour-1 .react-select__control * {
    color: #652f91;
    fill: #652f91;
}

.showColour-2 .react-select__control {
    border-color: #d60052 !important;
}

.showColour-2 .react-select__control * {
    color: #d60052;
    fill: #d60052;
}

.showColour-3 .react-select__control {
    border-color: #c2af24 !important;
}

.showColour-3 .react-select__control * {
    color: #c2af24;
}

.showColour-3 .sub-option svg path:nth-child(2){
fill:#c2af24
}

