.check-style {
    width: 33px;
    height: 33px;
}

.checkbox-blk .check-style{
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.checkbox-blk .form-check-input:checked[type=checkbox] {
    background-size: 18px !important
}

.sub-text {
    line-height: 30px !important;
}

.form-check-input:focus {
    box-shadow: none !important;
}
/* 
input[type=checkbox]:focus {
    outline: none !important;
} */

.ethnicity-style {
    background-color: #30BDBF;
    color: white;
    font-family: 'AlternateGothic', sans-serif;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 75px);
    ;
}

.style-subdrop {
    letter-spacing: 0.62px;
    font-family: museosans;
}

.acc-class {
    background-color: #68c7c8 !important;
    border-color: #68c7c8 !important;
}

.inp-mar {
    margin-left: 30px !important;
}