@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap');

html {
  font-size: 100%;
  line-height: 1.5;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* body {
  margin: 0;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #fffaf4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home {
  min-height: 90vh;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 4rem;
}

.wrapper--narrow {
  max-width: 800px;
}

h1 {
  font-size: 3.25rem;
  line-height: 1.2;
  margin: 0;
}

h1:not(:last-child) {
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.5rem;
  margin: 0;
}

h3:not(:last-child) {
  margin-bottom: 1rem;
}

p {
  font-size: 1.125rem;
  margin: 0;
}

p:not(:last-child) {
  margin: 0 0 1.125rem 0;
} */

@font-face {
  font-family: museosans;
  src: url(../assets/fonts/museosans.ttf);
}
@font-face {
  font-family: 'MuseoSans';
  src: url('../assets/fonts/MuseoSans-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('../assets/fonts/MuseoSans-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AlternateGothic';
  src: url('../assets/fonts/AlternateGotNo1D.woff2') format('woff2'),
    url('../assets/fonts/AlternateGotNo1D.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

btn:active:focus,
.btn:focus {
  outline-offset: 8px;
  box-shadow: none !important;
  background-color: white !important;
}
/* common css for accessibility */

h1:focus-visible,
h2:focus-visible,
h3:focus-visible,
h4:focus-visible,
h5:focus-visible,
h6:focus-visible,
p:focus-visible,
label:focus-visible,
span:focus-visible {
  outline-offset: 8px;
  outline: 2px solid #101010;
}

input:focus-visible {
  outline-offset: 8px;
  outline: 2px solid #101010;
}

textarea:focus-visible {
  outline-offset: 8px;
  outline: 2px solid #101010;
}

input[type='checkbox'] + .checkmark:focus-visible,
input[type='radio'] + .checkmark:focus-visible,
input[type='checkbox']:focus-visible + .faq-toggle {
  outline-offset: 8px;
  outline: 2px solid #101010;
}

input[type='file']:focus-visible {
  outline-offset: 8px;
  outline: 2px solid #101010;
  opacity: 1;
}

input:focus-visible + .checkmark,
input:focus-visible + .radio-checkmark,
input[type='checkbox']:focus-visible + span,
input[type='checkbox']:focus-visible + label,
input[type='radio']:focus-visible + span,
input[type='radio']:focus-visible + label,
input[type='radio']:focus-visible + .mc-radio,
input[type='checkbox'] + label:focus-visible,
input[type='radio'] + label:focus-visible,
input[type='checkbox']:focus-visible + .faq-toggle {
  outline: #101010 auto 2px !important;
  outline-offset: 8px;
}

//  *:focus {
//    outline: none;
//  }

input[type='checkbox']:focus-visible {
  outline: 2px solid #101010;
  outline-offset: 8px;
}

span[tabindex='0']:focus-visible,
form[tabindex='0']:focus-visible,
div[tabindex='0']:focus-visible {
  outline: 2px solid #29e70f;
  outline-offset: 8px;
}

button:focus-visible,
select:focus-visible {
  outline: #101010 auto 2px !important;
  outline-offset: 8px;
}

a:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 1px;
}

/* common css for accessibility end */
