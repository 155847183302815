.agebarrier-style {
    background-color: #D35296;
    color: white;
    min-height: calc(100vh - 75px);
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.h2-class {
    background-color: #d96aa4;
    border-color: #d96aa4;
}

.mar-op {
    margin-top: 8px;
}