.text-account {
  font-weight: 700;
  font-size: 18px;
  padding-top: 43px;
}

.sub-head {
  font-size: 18px;
}

.sub-head .text-account {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 24px;
}

.input-act {
  font-size: 22px !important;
  font-weight: 500;
  word-break: break-all;
}

.nav-link {
  color: black !important;
}

.nav-link:active {
  color: #652f91 !important;
}

.archive-text {
  font-size: 18px;
  font-weight: 700;
}

.nav-item {
  margin-bottom: 0px !important;
}

.submit-style {
  float: right;
  margin-top: 50px;
}

/* 
.footer-btn{
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;
} */

/* Message */

.message-blk .login-style1 {
  font-weight: 500 !important;
  font-size: 16px !important;
}

.mail-draft-blk {
  width: 100%;
  max-width: 750px;
  font-size: 16px;
  margin-left: -10px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  background-color: #fff;
  margin-top: 30px;
  padding: 10px;
  font-size: 16px;
}

.mail-draft-blk .css-1s2u09g-control {
  box-shadow: none !important;
  border: none !important;
}

.mail-draft-blk .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.mail-draft-blk .css-b62m3t-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mail-draft-blk .css-4ljt47-MenuList div:active,
.mail-draft-blk .css-4ljt47-MenuList div:hover,
.mail-draft-blk .css-4ljt47-MenuList div:focus,
.mail-draft-blk .css-1n7v3ny-option {
  background-color: #f5f7f9;
}

.mail-draft-blk .css-1pahdxg-control:hover,
.mail-draft-blk .css-1pahdxg-control {
  border-color: #fff !important;
}

.mail-draft-blk .css-4ljt47-MenuList div:hover {
  background-color: #f5f7f9;
}

.mail-draft-blk .css-1pahdxg-control:hover {
  border-color: none !important;
}

.mail-draft-blk {
  outline: none !important;
}

.mail-draft-blk .css-1rhbuit-multiValue {
  border: 1px solid #d1d3d4;
  border-radius: 16px;
  padding: 0px 8px 0px 14px;
  background-color: #fff;
}

.mail-draft-blk .css-xb97g8:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.custom-option {
  height: 50px;
  padding: 10px 10px 10px 15px;
}

.custom-option h2,
.inbox-preview .sub-head h3 {
  font-size: 16px;
  font-weight: 700;
}

.custom-option .sub,
.inbox-preview .sub-head p {
  color: #71716f;
}

.message-blk .border,
.inbox-preview .border {
  border-bottom: 1px solid #d1d3d4;
}

.message-blk .login-radius {
  box-shadow: none !important;
  height: 50px;
  font-size: 16px;
  margin-top: 0;
}

.message-blk textarea {
  font-weight: 500;
  font-size: 16px;
  max-width: 100% !important;
  margin-top: 10px;
  min-height: 250px !important;
  border: none;
  background-color: white !important;
}

.message-blk .submit-style {
  margin: 30px 20px 30px 30px !important;
}

.message-blk .success-msg {
  height: 25px;
  padding: 0px 15px 0px 15px;
  border: 1px solid #8cc63f;
  background-color: #e6ffc6;
  color: black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  max-width: fit-content;
  margin-left: -5px;
}

.message-blk .success-msg span {
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* inboxpage */

.inbox-blk {
  font-size: 16px;
  font-family: museosans;
}

.inbox-blk .card {
  border-radius: 10px !important;
  min-height: 50px;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: none;
  margin-bottom: 10px;
  display: grid !important;
  grid-template-columns: 4fr 10fr 1fr;
  padding: 25px;
  min-width: 1500px;
  cursor: pointer;
}

.mail-card {
  overflow: auto;
  margin-top: 30px;
  font-size: 18px;
}

.mail-card span {
  font-size: 16px !important;
  color: #9e9e9e;
}

.mail-card .sub-span {
  color: #000 !important;
}

.mail-card p:nth-child(1) {
  text-align: left;
}

.mail-card p:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 800px;
}

.mail-card p:nth-child(3) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.mail-card .sub-font {
  font-weight: 700;
}

.inbox-blk .day {
  margin: 30px 0px 30px 0px;
}

.inbox-blk .active-color {
  color: #652f91;
}

/* preview page of inbox */

.inbox-preview {
  border-radius: 10px !important;
  width: 100%;
  max-width: 650px;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: none;
  margin-top: 30px;
  font-size: 16px;
  background-color: #ffffff;
}

.inbox-preview .header {
  display: flex;
  padding: 15px;
  color: #652f91;
  font-size: 18px;
  font-weight: 600;
}

.inbox-preview .header p {
  width: 100% !important;
}

.inbox-preview img {
  margin-right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.inbox-preview textarea {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f5f7f9 !important;
  width: 100%;
}

/* .inbox-preview textarea:focus-visible {
  outline: none;
} */

.inbox-preview .sub-head {
  padding: 15px;
}

.inbox-preview .sub-head p:nth-child(1) {
  margin-right: 10px;
}

.inbox-preview .sub-head p {
  font-size: 14px;
}

.inbox-preview .sub-head div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.inbox-preview .body {
  padding: 15px;
  font-size: 16px;
}

.inbox-preview .body p {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.inbox-preview .body textarea {
  background-color: #fff !important;
}

.inbox-preview .body img {
  float: right;
  width: 20px;
  height: 20px;
  margin: 0;
}

.inbox-preview .delete {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 0px 20px 0px;
}

/* sent-blk page */

.sent-blk {
  margin-top: 30px;
}

.sent-blk .status {
  color: #b70000;
}

.sent-blk .card {
  grid-template-columns: 1fr 10fr 1fr !important;
}

.sent-blk p {
  text-align: left;
}

.sent-blk p:nth-child(1) {
  width: 250px;
}

.sent-blk p:nth-child(2) {
  width: 800px;
}

.sent-blk p:nth-child(3) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.sent-blk p:nth-child(4) {
  text-align: left;
}

/* bin page */

.bin-blk img {
  text-align: left;
}

.bin-blk img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bin-blk .main-title {
  word-break: break-word;
  max-width: 600px !important;
  width: 100% !important;
}

.bin-blk p:nth-child(2) {
  width: 250px !important;
}

.restore-blk {
  position: relative;
}

.restore-blk:hover + .sub-tool {
  padding: 3px 5px 3px 5px;
  background-color: black;
  border-radius: 4px;
  color: white;
  font-size: 10px;
  position: absolute;
  bottom: -23px;
  left: 8px;
  z-index: 20;
  display: block !important;
}

.restore-blk img {
  width: 24px;
  height: 24px;
  margin-left: 18px;
}

.bin-blk p:nth-child(3) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 800px;
  text-align: left;
}

.bin-blk p:nth-child(4) {
  text-align: left;
}

.bin-blk .card {
  cursor: pointer;
  grid-template-columns: 1fr 1fr 6fr 0.5fr !important;
}

.empty-bin {
  padding: 0px 10px 0px 10px;
  height: 50px;
  background-color: #3b4250;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  max-width: 850px;
  position: fixed;
  bottom: 10px;
}

.empty-bin span {
  color: #60c8ee;
  cursor: pointer;
}

.option p {
  font-weight: 700;
}

.option label {
  font-weight: 500 !important;
  color: #868889;
}

.empty-record {
  font-size: 18px;
  text-align: center;
  overflow: unset !important;
}
