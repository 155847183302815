.secondary-select .react-select__control {
  padding-left: 10px !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  border: 1px solid #cdcdcd !important;
  min-height: 60px !important;

}

.secondary-select .react-select__control--is-focused {
  border: 1px solid #cdcdcd !important;
  box-shadow: 0 0 0 0 #cdcdcd !important;
}

.secondary-select .react-select__indicator.react-select__dropdown-indicator svg {
  height: 10px;
  width: 18px;
  padding-right: 5px;
}
.grid-blk .secondary-select .react-select__indicator.react-select__dropdown-indicator svg {
  height: 25px;
  width: 30px;
  padding-right: 5px;
}

.secondary-select .react-select__placeholder {
  font-size: 16px !important;
  color: #707070;
}

.secondary-select .secondary-select .react-select__option.react-select__option {
  font-size: 16px !important;
  font-weight: 300 !important;
}

.secondary-select .react-select__menu {
  margin-top: 0 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.secondary-select .react-select__menu-list {
  color: #3B4250;
  font-size: 16px;
}

.secondary-select .react-select__single-value {
  color: #3B4250 !important;
  font-size: 18px !important;
  font-weight: 300;
}

.secondary-select .react-select__option--is-focused {
  border: 1px solid #9C9C9C !important;
}
.grid-blk .secondary-select .react-select__option--is-focused {
  border: none !important;
}

.secondary-select .react-select__option.react-select__option--is-selected {
  background-color: #ffffff;
  color: #3B4250;
}

.secondary-select .react-select__option.react-select__option--is-focused {
  background-color: #652F91 !important;
  color: #ffffff !important;
}

.grid-blk .secondary-select .react-select__option.react-select__option--is-focused {
  background-color: #E7E8EA !important;
  color: #000000 !important;
}


/* Permission management location field dropdown customize */
.location-block .react-select__menu-list {
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 20px;
  row-gap: 20px; */
}

.location-block .react-select__option {
  border: 1px solid #cdcdcd;
  border-radius: 20px;
  color: #652F91;
  width: auto;
}

.location-block .react-select__option.react-select__option--is-focused {
  background-color: #FFF !important;
  color: #652F91 !important;
}

.role-block .react-select__control,
.location-block .react-select__control {
  background-color: #FFF !important;
}

.location-block .react-select__control .react-select__multi-value {
  background-color: #3B4250;
  padding: 10px;
  border-radius: 20px;
  padding: 5px 15px;
  margin-right: 15px;
}

/* remove icon */
.location-block .react-select__control .react-select__multi-value .react-select__multi-value__remove {
  color: #FFF;
}

.location-block .react-select__multi-value__label {
  color: #FFF;
  font-size: 16px;
}

/* End of Permission management location field dropdown customize */