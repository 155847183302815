.select-style {
    font-family: museosans;
    font-size: 17px;
    width: 100%;
    height: 50px;
}

.select-style-sign{
    font-family: museosans;
    font-size: 17px;
    width: 100%;
    height: 50px;
    max-width: 250px;
    background-image: url('../../src/assets/images/down-arrow.svg') !important;
}
.select-style:focus{
    border-color: grey;
}

.postcodeage-style {
    background-color: #582E7A;
    color: white;
    min-height: calc(100vh - 75px);
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn-style-pos {
    padding-top: 69px;
}

.mar-pos {
    margin-top: 41px;
}

.btn-bo {
    bottom: -56px !important;
}