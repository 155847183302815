.admin-flag{
    display: inline-block;
    padding: 6px 15px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    /* border-bottom: 5px solid white; */
    color: #fff;
    background-color: #652F91;
}
.admin-flag.red{
    color: #fff;
    background-color: #C10248;
}
.admin-flag.yellow{
    color: #3B4250;
    background-color: #F7BE00;
}
.admin-flag.green{
    color: #fff;
    background-color: #5A9F00;
}
.admin-flag-align{
    padding-left: 18px;
}