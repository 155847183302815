.preinfo-style {
    background-color: #582e7a;
    color: white;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: calc(100vh - 75px);
    ;
    position: relative;
    padding-bottom: 100px;
}

.confi-style {
    background-color: #582e7a;
    color: white;
    font-family: 'AlternateGothic', sans-serif;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: calc(100vh - 75px);
    position: relative;
    padding-bottom: 100px;
}

.pep-anchor-btn {}

.list-blk ul {
    list-style: unset !important;
    padding-left: 30px;
    padding-right: 30px;
}

@media (min-width: 1025px) {
    .btm-btn {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        padding: 30px;
        text-align: center;
    }
}

.footer-class-info {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 30px;
    text-align: center;
}
.steps-footer{
    flex-direction: column;
    align-items: flex-end;
}
.steps-footer button:first-child{
    margin-bottom: 16px !important;
}
.btm-btn {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 30px;
    text-align: center;
}


/* .btn-width {
    width: 240px !important;
} */

.font-a {
    font-family: museosans;
    text-decoration: underline;
    font-size: 18px;
    color: white;
    cursor: pointer;
}

.font-a:hover {
    color: white !important;
}

.a-pos {
    position: absolute;
    right: 35px;
    bottom: -15px;
    padding-top: 0px !important;
}

.footer-pos {
    bottom: 40px;
}

.para-fo {
    font-family: museosans !important;
    font-style: normal !important;
    letter-spacing: 1px;
    font-size: 18px !important;
    word-spacing: 1px;
}

.no-style {
    position: relative;
}

.jus-space {
    justify-content: space-between;
}

.p-style {
    font-size: 183px;
    line-height: 180px;
    font-family: museosans !important;
}

.po-style {
    width: 100px;
}

.pww-pos {
    font-size: 183px;
}

.mar .margin-btn {
    margin-top: 115px;
}

.p-pos {
    position: absolute;
    font-size: 150px;
    top: -44px;
    left: 4px;
    font-family: 'museosans';
}

@media (min-width:1025px) {
    .fo-size {
        font-size: 19px !important;
    }
}

.mar-left {
    margin-left: 50px;
}

.b-bot {
    bottom: 40px !important;
}

/* a:hover {
    color: white;
    cursor: pointer
} */

@media (min-width: 320px) and (max-width: 550px) {
    .accordion-fulwidth {
        margin: 0px -30px;
        width: calc(100%- -60px);
    }

    .panel__content {
        margin: 5px 25px 25px;
    }

    .pre-info-footer-blk{
        padding-top: 90px !important;
    }

    .steps-footer.b-bot{
        bottom: 0 !important;
    }
}

@media (min-width: 550px) and (max-width: 768px) {
    .accordion-fulwidth {
        margin: 0px -30px;
        width: calc(100%- -60px);
    }

    .panel__content {
        margin: 5px 25px 25px;
    }
}