.admin-bg{
    background-color: #F5F7F9;
}
.admin-blk{
    position: relative;
     font-family: 'MuseoSans', sans-serif; 
   /* font-family: 'Lexend', sans-serif; */
}
.admin-sidebar{
    width: 80px;
    height: 100vh;
    position: fixed;
    background-color: #652F91;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 12%);
    z-index: 999;
}
.admin-sidebar .logo-blk{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #fff;
}
.admin-sidebar .bottom{
    position: absolute;
    bottom: 20px;
}
@media (max-width:1024px) {
    .admin-sidebar .bottom{
        position: relative !important;
        bottom: 0!important;
    }
}
.admin-sidebar .bottom a, .admin-sidebar .bottom div{
    width: 80px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 400ms;
    cursor: pointer;
}
.admin-sidebar .bottom a:hover, .admin-sidebar .bottom div:hover{
    background-color: rgba(255, 255, 255, 5%);
}
.admin-sidebar .bottom a.active, .admin-sidebar .bottom div.active{
    background-color: rgba(255, 255, 255, 15%);
}
.admin-content-wrap{
    padding-left: 80px;
    width: 100%;
    min-height: 100vh;
}
.admin-container{
    max-width: 1300px;
    margin: auto;
}
.admin-menu-header{
    display: flex;
    align-items: center;
    background-color: #fff;
    min-height: 80px;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 999;
}
.admin-menu-header .accordian-dropdown,
.admin-menu-header .admin-role-status p,
.admin-menu-header .admin-top-nav{
    /* position: static !important; */
}
.admin-top-nav{
    width: calc(100% - 465px);
    height: 80px;
    display: flex;
    justify-content: flex-start;
    vertical-align: bottom;
    align-items: flex-end;
    background: #ffffff;
    z-index: 998;
    overflow: auto;
    white-space: nowrap;
}
.admin-top-nav.menus-section{
    width: 90% !important;
}
.log-in-blk .admin-top-nav{
    width: calc(100% - 80px) !important;
}
@media (min-width:1500px){
    .admin-top-nav{
        justify-content: center;

    }
}
.admin-top-nav .accordian-dropdown{
    margin-right: 16px;
}
.admin-top-nav a{
    margin: 0px 15px;
    padding: 25px;
    font-size: 18px;
    color: #652F91;
    border-bottom: 3px solid transparent;
}
.admin-top-nav a:hover{
    color: #652F91;
    text-decoration: none;
}
.admin-top-nav a.active{
    font-weight: 700;
    border-bottom: 3px solid  #652F91;
}
.admin-role-status{
    position: relative;
    z-index: 998;
}
.admin-role-status p{
    position: fixed;
    top: 0;
    right: 0;
    padding: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #3B4250;
    letter-spacing: 1.5;
    text-transform: uppercase;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.9), rgba(255,255,255,1));
}
.admin-content-blk{
    padding: 120px 40px 50px 40px;
}
@media (max-width:767px) {
    .admin-content-blk{
        padding: 80px 10px 20px 10px;
    }
}
@media (max-width: 991px){
    .admin-content-wrap{
        padding-left: 70px;
    }
    .admin-sidebar{
        width: 70px;
    }
    .admin-sidebar .logo-blk{
        width: 70px;
        height: 70px;
    }
    .admin-sidebar .bottom a{
        width: 70px;
        height: 70px;
    }
    .admin-top-nav{
        height: 70px;
        justify-content: flex-start;
        overflow-x: auto;
    }
    .admin-top-nav a{
        padding: 20px;
        font-size: 16px;
    }
    .admin-role-status p{
        padding: 26px;
        font-size: 12px;
    }
}
@media (max-width: 768px){
    .admin-content-wrap{
        padding-left: 60px;
    }
    .admin-sidebar{
        width: 60px;
    }
    .admin-sidebar .logo-blk{
        width: 60px;
        height: 60px;
    }
    .admin-sidebar .bottom a{
        width: 60px;
        height: 60px;
    }
    .admin-top-nav{
        height: 60px;
    }
    .admin-top-nav a{
        padding: 18px;
        font-size: 15px;
    }
    .admin-role-status p{
        display: none;
    }
}
@media (max-width: 567px){
    .admin-content-wrap{
        padding-left: 60px;
    }
    .admin-sidebar{
        width: 60px;
    }
    .admin-sidebar .logo-blk{
        width: 60px;
        height: 60px;
    }
    .admin-sidebar .bottom a{
        width: 60px;
        height: 60px;
    }
    .admin-top-nav{
        height: 60px;
    }
    .admin-top-nav a{
        padding: 16px;
        font-size: 14px;
    }
}
@media (max-width: 480px){
    .admin-content-wrap{
        padding-left: 50px;
    }
    .admin-sidebar{
        width: 50px;
    }
    .admin-sidebar .logo-blk{
        width: 50px;
        height: 50px;
    }
    .admin-sidebar .bottom a{
        width: 50px;
        height: 50px;
    }
    .admin-top-nav{
        height: 50px;
    }
    .admin-top-nav a{
        padding: 12px;
    }
}
.primary-btn {
    @apply border;
    background-color: rgb(92 44 104) !important;
    color: rgb(255 255 255) !important;
    border-color: rgb(92 44 104);
  }
  .primary-btn:hover {
    background-color: rgb(92 44 104);
    color: rgb(255 255 255) !important;
  }
  .primary-btn:active {
    background-color: rgb(92 44 104);
  }
  .btns {
    @apply cursor-pointer gap-2 rounded-lg py-2 px-6 text-base font-semibold no-underline focus:outline-none;
    min-height: 48px !important;
  }
  .css-1s2u09g-control {
    background-color: transparent;
    border-color: rgb(197 197 197);
    padding: 5px !important;
    border-radius: 4px !important;
  }
  .css-1pahdxg-control{
    border-color:rgb(92 44 104) !important;
    padding:  5px !important;
    border-radius: 4px !important;
  }