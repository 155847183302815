.in-lilie {
  display: inline-flex;
  flex-direction: row;
  justify-content: right;
  vertical-align: unset;
  align-content: space-between;
}
.in-lilie .check-input {
  margin: 0px;
  width: 24px;
  height: 24px;
}
#checkbox-pr {
  cursor: default;
}

.container {
  height: 24px;
  display: block;
  position: relative;
  /* padding-left: 35px;
    margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 0;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-width: 0px;
  background-color: #e7e9eb;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #e7e9eb;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #652f91;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.disable .add-pad{
  margin-left: 20px !important;
}

.in-lilie .add-pad{
  margin-left: 22px;
}