.sec-nav-wrap{
    position: relative;
    display: block;
}
.sec-nav-blk{
    display: flex;
}
.sec-nav-blk a{
    padding: 25px;
    font-size: 22px;
    font-weight: 500;
    color: #652F91;
    color: #3B4250;
    text-decoration: none;
}
.sec-nav-blk a:hover{
    text-decoration: none;
    color:black
}
.sec-nav-blk a.active{
    color: #652F91;
    border-bottom: 3px solid #652F91;
}
.sec-nav-wrap hr{
    margin: -3px 0px 0px 0px;
    height: 2px;
    border-color: #D8DFE6;
}
.sec-nav-wrap .right-content{
    position: absolute;
    top: 0;
    right: 0;
}
.display-sync {
    display: none !important;
}