/* The container */
.sti-kit-radio-blk .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: auto !important;
}

/* Hide the browser's default radio button */
.sti-kit-radio-blk .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.sti-kit-radio-blk .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
}

/* When the radio button is checked, add a blue background */
.sti-kit-radio-blk .container input:checked~.checkmark {
  background-color: #FFF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.sti-kit-radio-blk .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.sti-kit-radio-blk .container input:checked~.checkmark:after {
  display: block !important;
}

/* Style the indicator (dot/circle) */
.sti-kit-radio-blk .container .checkmark:after {
  width: 12px !important;
  height: 12px !important;
  background-color: #f1873f !important;
  border-width: 0 !important;
  left: 52% !important;
  top: 5px !important;
  transform: translate(-50%, 0) !important;
  border-radius: 10px;
}

.sti-kit-radio-blk label {
  display: flex !important;
  font-weight: 400;
  font-family: museosans;
  /* font-size: 20px !important; */
}

.sub-title-blk {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: museosans;
  padding-left: 35px;
}

.edit-kit-note-blk {
  padding-left: 35px;
}

.edit-kit-note-blk .Calm>p,
.customise-kit-note-blk .Calm>p {
  color: #244472 !important;
}