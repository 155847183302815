.login-subtitle {
  color: #757778;
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}

.login-blk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 310px);
}

.login-note {
  color: #757778;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 592px;
  width: 100%;
  text-align: center;
}

.azure-btn-blk {
  display: flex;
  padding: 1rem;
  background-color: #3B4250;
  border-radius: 10px;
}

.azure-btn {
  display: flex;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
}

.azure-btn-label {
  display: inline-block;
  padding: 0 2rem;
}