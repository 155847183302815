.myth-contr-box {
  position: relative !important;
  width: 115px;
  max-width: 115px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 644px) {
  .myth-contr-box {
    margin-top: 10px;
  }
}

.chat-bubble-border-green {
  border: 3px solid #329632 !important;
}

.chat-bubble-border-pink {
  border: 3px solid #c93488 !important;
}

.chat-bubble-border-orange {
  border: 3px solid #fc601a !important;
}

.slick-next,
.slick-prev {
  color: white !important;
}

.slick-next,
.slick-prev:hover {
  color: white !important;
}

/* next myth */

/* .next-myth, .next-myth:hover  {
    text-decoration: underline;
    color: white;
    font-size: 18px;
    cursor: pointer;
  } */

/* choose topic class */

.content-container .choose-topic-margin {
  margin-left: -10px;
}

.myth-topic {
  font-size: 16px;
}

.slick-slide.slick-active .container-slider-hide .content-container {
  display: block;
}
.slick-slide .container-slider-hide .content-container {
  display: none;
}
.align-sub-content {
  text-align: center;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}

.align-sub-content a:hover,
.align-sub-content a:focus {
  color: #fff;
}

.without-slider-section .content-container {
  width: 100% !important;
}

.with-slider-section .content-container {
  width: 96% !important;
}

.slick-disabled .custom-next-arrow {
  @apply opacity-50;
}
.slick-prev:before,
.slick-next:before {
  @apply hidden;
}
