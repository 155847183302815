.popup-text {
  font-size: 14px;
  font-weight: 700;
  margin-top: 18px;
}

.content-height {
  height: 300px;
}

/* .flow-sc{
    overflow:scroll;
} */

.email-col {
  color: #8f949d !important;
}

.shadow-box {
  box-shadow: 0 2px 3px 1px rgb(0 0 0 / 10%) !important;
  border-radius: 50px;
  height: 60px;
  border: none;
  margin-top: 12px;
}

.popup-btn {
  padding: 10px 25px 10px 25px !important;
  margin-top: 0px !important;
  font-size: 14px !important;
}

.close-pop {
  font-weight: 700;
  color: #652f91;
  text-decoration: underline;
  cursor: pointer;
}

.bor-none {
  border-top: none !important;
  padding: 15px 0px 0px 0px;
}

.bg-color-popup {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.fo-we {
  font-weight: 500 !important;
}

.font-we {
  font-weight: 700 !important;
}

.pad-title {
  padding-top: 12px;
  /* margin-left: 32px; */
}

.modal-header {
  border-bottom: none !important;
}

.close-pos {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.close-white-pos {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 28px;
}

.class-thi {
  padding: 0px 40px 40px 40px;
}

.pad-head {
  padding: 22px !important;
}

.dr-style {
  font-size: 22px;
  color: #652f91;
  font-weight: 700;
}

.sec-title {
  font-weight: normal !important;
}

.check-style {
  width: 24px;
  height: 24px;
  border-radius: 0px !important;
}

.checkbox-base {
  display: inline-block !important;
}

.checkbox-wi {
  width: 0% !important;
  display: inline-block !important;
}

.label-class {
  font-weight: 500;
}

.pos-check {
  margin-top: 10px;
  position: relative;
}

.pos-input {
  position: absolute;
  left: 90px;
}

.pop-inp {
  background-color: #e7e9eb;
  width: 400px;
}

.pop-inp:focus {
  background-color: #e7e9eb;
  border: none !important;
  box-shadow: none !important;
}

.sub-head-style {
  font-weight: 700;
  font-size: 19px;
  font-family: museosans;
}

@media (min-width: 1200px) {
  .modal-width {
    max-width: 1300px !important;
  }
}

.pad-top {
  padding-top: 30px;
}

.sub-rply {
  font-size: 14px;
  font-family: museosans;
  font-weight: 500;
  margin-right: 10px;
  white-space: break-spaces;
}

.pad-admin {
  padding: 30px !important;
  border-radius: 11px 0px 0px 11px;
}

.bor-ra-bottom {
  border-bottom: 1px solid #ccc;
}

.bor-ra-right {
  border-right: 1px solid #ccc;
}

.mar-b {
  margin-bottom: 0px !important;
}

.mar-top {
  margin-top: -19px;
}

.pad-left {
  padding-left: 45px !important;
}

.ethnicity-pad {
  padding-left: 28px;
}

.pos-abs {
  position: absolute;
  right: 44px;
  bottom: 7px;
}

.pos-check {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ri-st {
  right: -38px !important;
  bottom: -8px !important;
}

/* popup header */

.pos-header {
  position: relative;
  padding-right: 0px !important;
}

.pos-content {
  position: absolute;
  right: 12px;
  top: 36px;
}

.content-style {
  font-size: 14px;
  color: #3b4250;
  font-weight: 700;
}

.reject-title {
  font-size: 27px;
  font-weight: 700;
  white-space: break-spaces;
}

.reject-pop-link {
  color: #652f91;
  font-size: 14px;
  text-decoration: underline;
}

.reject-pop-link:hover {
  color: #652f91;
  text-decoration: underline;
}

.reject-des {
  left: 49px;
  position: absolute;
  bottom: 26px;
}

.reject-pop {
  max-width: 600px;
}

.pad-top-impo {
  padding-top: 12px !important;
}

/* client ppad-topopup */

.profile-header {
  height: 70px;
  background-color: #652f91;
  justify-content: start !important;
  color: #ffff;
}

.pad-dob {
  padding-left: 28px;
}

.align-pad {
  padding-left: 30px;
}

.rotate-mode {
  transform: rotate(-90deg);
}

.notes-col {
  column-width: 27em;
}

@media (max-width: 992px) {
  .notes-col {
    column-width: 10em;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .notes-col {
    column-width: 25em;
  }
}

.phone-section,
.email-section {
  display: flex;
  word-break: break-all;
}

.phone-section .sub-rply {
  margin-left: 13px;
}

.email-section .sub-rply,
.gender-section .sub-rply,
.code-section .sub-rply {
  margin-left: 17px;
}

@media (min-width: 768px) {
  .client-model-width {
    width: auto !important;
    max-width: 1480px !important;
  }
}

.align-status {
  display: flex;
  flex-wrap: nowrap;
}

.align-status div {
  margin-top: 20px;
  max-width: 170px;
  white-space: break-spaces;
}

/* .align-status .set-col-width {
  max-width: 255px !important;
} */

.strike-off {
  font-weight: normal;
  font-style: italic;
  text-decoration: line-through;
  opacity: 0.8;
}

.demo-width {
  max-width: 280px;
}

.set-width {
  max-width: 277px;
}

.client-popup .set-width {
  max-width: 277px;
}

.profile-popup-height {
  height: 500px;
  overflow: auto;
}

.text-head-color {
  color: #80848e;
}

.client-popup {
  font-size: 14px;
}

.close-white {
  width: 10px;
  height: 10px;
}

.footer-style {
  border-top: none !important;
  padding: 15px 0px 0px 0px;
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .footer-style {
    display: block !important;
  }
}

.body-h {
  overflow: auto;
}

.custo-class {
  height: 23px;
  padding: 1px 15px !important;
  position: relative !important;
}

.pad-admin-pop {
  padding: 30px 0px 25px 0px;
}

.location-btn {
  color: #673d91;
  border: 1px solid #673d91;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  transition: all 0.1s linear;
}

.location-btn:hover {
  color: #fff;
  background-color: #673d91;
  border: 1px solid #673d91;
}

@media (min-width: 768px) {
  .modal-width-staff {
    width: 1000px !important;
  }
}

.modal-h {
  height: 600px;
  overflow: auto;
}

.go-back {
  padding: 10px 25px;
  width: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 26px;
  background-color: #ffff;
  border: 2px solid #652f91;
  color: #652f91;
}

.go-back:hover {
  background-color: #652f91;
  color: #ffff;
}

.select-width {
  max-width: 500px;
}

.region-select {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 37px;
}

.pos-right {
  right: -16px !important;
}

span.outdated {
  color: red;
  font-size: 11px;
}

.export-btn {
  display: flex;
  justify-content: flex-end;
}

.in-lilie .disable div:nth-child(1) {
  padding-left: 20px;
}

.border-style {
  margin-top: 30px;
  border-bottom: 2px solid #cbcbcb;
}

.parent-pos .sub-head {
  padding-top: 20px;
}

.parent-contact {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 20px;
}

.parent-contact .type {
  width: 100%;
  max-width: 170px;
}

.parent-contact .date {
  width: 100%;
  max-width: 120px;
  color: #14141c;
}

.date .disable input {
  background-color: #e6e6e6 !important;
  border-color: hsl(0, 0%, 90%) !important;
}

.time .react-time-picker--disabled .react-time-picker__inputGroup {
  border: 1px solid #e6e6e600 !important;
}

.dropdown-class .css-tj5bde-Svg,
.result .css-tj5bde-Svg {
  fill: #363636;
}

.dropdown-class .css-109onse-indicatorSeparator,
.result .css-109onse-indicatorSeparator {
  display: none;
}

.dropdown-class .css-1insrsq-control .css-tj5bde-Svg {
  fill: #757579;
}

.dropdown-class .css-1insrsq-control .sub-option {
  color: #727277;
}

.parent-contact .css-1pahdxg-control {
  border: 1px solid #3c424f !important;
}

.parent-contact .date input {
  border: 1px solid grey;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
  padding: 5px;
  max-width: 130px;
  width: 100%;
  height: 38px;
}

.parent-contact .time input {
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
  width: 100%;
  border-color: #00000029 !important;
}

.parent-contact .time img {
  margin-left: -28px;
  /* z-index: 1; */
}

/* .parent-contact .date input:focus-visible,
.parent-contact .time input:focus-visible {
  outline: none;
} */

.parent-contact .time {
  width: 100%;
  max-width: 127px;
  height: 38px !important;
}

.parent-contact .result {
  width: 100%;
  max-width: 217px;
}

@media (min-width: 768px) {
  .contact-attempt-popup {
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
  }
}

.contact-attempt-popup .model-bor {
  border: 1px solid #707070 !important;
  border-radius: 20px !important;
}

.contact-attempt-popup .popup-btn {
  font-size: 18px !important;
  padding: 8px 28px !important;
}

.contact-attempt-popup .cancel-btn,
.contact-attempt-popup .cancel-btn:hover,
.contact-attempt-popup .cancel-btn:hover,
.contact-attempt-popup .cancel-btn:focus {
  background-color: white !important;
  color: #652f91;
  border: 1px solid #652f91 !important;
}

.contact-attempt-popup .modal-footer {
  padding: 0px 30px 75px 30px !important;
  border: none !important;
}

.attempt-btn,
.attempt-btn:hover,
.attempt-btn:active,
.attempt-btn:focus {
  background-color: #f7f4fa !important;
  border: none !important;
  color: #652f91;
  padding: 8px 28px !important;
  border-radius: 26px;
  font-size: 18px;
}

/* .attempt-btn:focus-visible {
  outline: none !important;
} */

.contact-attempts-block {
  height: 460px;
  overflow: auto;
}

.dropdown-class {
  width: 100%;
  max-width: 130px;
}

.result .css-1pahdxg-control {
  width: 100%;
  min-width: 130px;
}

.parent-contact .css-1s2u09g-control {
  box-shadow: none !important;
  border: 1px solid #3c424f !important;
}

.css-qc6sy-singleValue {
  display: flex !important;
  margin-top: 0px !important;
}

.parent-contact .css-1n7v3ny-option:hover {
  background-color: #f2f2f2 !important;
  cursor: pointer;
}

.parent-contact .css-9gakcf-option {
  background-color: #f2f2f2 !important;
  color: black !important;
}

.warning-msg-attempt {
  display: flex;
  color: #ff6700;
  font-size: 18px;
}

.warning-msg-attempt span {
  padding-left: 10px;
  padding-top: 6px;
}

.warning-msg-attempt img {
  margin-top: 3px;
}

.sub-btn {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

.result .css-26l3qy-menu {
  width: 250px !important;
}

.final-result-1 {
  color: #df2262;
}

.final-result-2 {
  color: #5e328c;
}

.final-result-3 {
  color: #c2af24;
}

/* .result-border{
  border-left: 1px solid grey;
  height: 43px;
  position: absolute;
  top: 60px;
  right: 238px;
}

@media (max-width:768px) {
  .result-border{
    right: 241px;
  }
} */

.css-319lph-ValueContainer {
  margin-top: 0;
}

.parent-contact .date {
  position: relative;
}

.parent-contact .date .calender-img {
  position: absolute;
  right: 5px;
  top: 12px;
  pointer-events: none;
}

.date .react-datepicker__month-container {
  z-index: 10;
}

.parent-contact .react-datepicker__month-container {
  background-color: #fff !important;
}

.date .react-datepicker__day:hover {
  color: white !important;
  background-color: grey !important;
}

.time .react-time-picker__clear-button,
.time .react-time-picker__clock-button,
.time .react-time-picker__clock {
  display: none;
}

.time .react-time-picker__inputGroup {
  padding: 5px;
  border: 1px solid #3c424f !important;
  border-radius: 4px;
}

.time .react-time-picker--closed,
.time .react-time-picker--open {
  width: 100%;
}

.time img {
  pointer-events: none;
}

.time .react-time-picker__wrapper {
  border: thin solid #e6e6e6 !important;
  border-radius: 4px !important;
}

.archive-main-content,
.sub-title-text {
  white-space: break-spaces;
}

.sub-rply .bold {
  font-weight: 600;
}

.sub-rply span {
  color: #757778;
  font-size: 14px;
}
