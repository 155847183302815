.noti-pop-blk {
  position: absolute;
  bottom: 80px;
  left: 100px;
}
.noti-pop-blk:after {
  content: '';
  position: absolute;
  left: -20px;
  top: 26px;
  z-index: 999;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 20px solid #fff;
}
.noti-pop-wrap {
  width: 292px;
  height: 288px;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.noti-pop-wrap .head {
  display: flex;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 0;
  padding: 20px 0px 10px 0px;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  background-color: #fff;
}
.noti-pop-wrap .head a {
  color: #652f91;
  text-decoration: underline;
}
.noti-pop-wrap .content {
  margin-top: 36px;
  padding: 0px 20px;
  /* height: 205px; */
  overflow-y: auto;
}
.content {
  cursor: pointer;
}
.noti-pop-wrap .noti-card {
  margin-bottom: 3px;
  padding: 20px;
  background-color: #f4f7f9;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  max-width: 266px;
  margin-left: 13px;
}
.noti-pop-wrap .noti-card .noti-info {
  padding-bottom: 10px;
}
.noti-pop-wrap .noti-card .noti-action {
  margin: 0px !important;
  padding: 0px !important;
  color: #652f91;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
}

/* Notification page */
.noti-page .noti-card {
  margin-bottom: 15px;
  padding: 20px;
  background-color: #f4f7f9;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  max-width: 266px;
  margin-left: 13px;
}
.noti-page .noti-card .noti-info {
  padding-bottom: 10px;
}
.noti-info {
  cursor: pointer;
}

.noti-page .noti-card .noti-action {
  margin: 0px !important;
  padding: 0px !important;
  color: #652f91;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.noti-mar {
  margin-top: 32px;
  margin-right: 10px;
}
.overf-card {
  height: 220px;
  overflow: auto;
}
.noti-card{
  cursor: pointer;
}