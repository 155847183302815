.table-overflow {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.table-view {
    width: 100%;
    padding-top: 20px;
    margin-left: -11px;
    /* position: relative; */
}

.table-view tbody:last-child {
    bottom: 15px !important;
}

.table-view.less-than-5 {
    width: 100%;
}

.table-view.equal-to-6 {
    width: 100%;
}

tr.selected-row-blk {
    /* box-shadow: -5px 5px #31BDBF; */
    border-radius: 10px !important;
    box-shadow: rgba(49, 189, 191, 0.9) -5px 5px;
}

@media(min-width: 1800px) {
    .rejected-table-width {
        min-width: 1800px !important;
    }

    .table-view {
        min-width: 1980px;
    }

    .search-table-width {
        min-width: 2500px;
    }

    .admin-table-width {
        min-width: 1650px
    }
}

@media(min-width: 768px) {
    
    .positive-table-width {
        min-width: 2400px !important;
    }
}


.negative-width {
    min-width: 2500px !important;
}

.positive-width {
    min-width: 2800px !important;
}


@media(max-width: 1800px) {
    .table-view {
        min-width: 1980px;
    }

    .search-table-width {
        min-width: 2500px;
    }

    .admin-table-width {
        min-width: 1650px
    }

    .table-view.less-than-5 {
        width: 100%;
    }

    .table-view.equal-to-6 {
        min-width: 1200px;
        max-width: 100%;
    }
}

@media (max-width: 1800px) {
    .lilie-width {
        min-width: 2200px !important;
    }
}

@media(max-width: 768px) {
    .table-view {
        width: 100%;
    }
}

.sort-blk-10 {
    left: 250px !important
}

.sort-blk-12 {
    left: 250px !important
}


.sort-blk-15 {
    left: 265px !important
}

.table-view table {
    border-collapse: collapse;
    margin: 0 15px;
    padding: 0;
    width: 100%;
    table-layout: auto;
    padding-right: 10px;
}

.table-view table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.table-view table th,
.table-view table td {
    padding: .625em;
}

.table-view table th {
    font-weight: 400;
}

.table-view table td {
    padding: 1.2em 0.625em;
    box-shadow: 0px 3px 0px rgb(0 0 0 / 10%);
    cursor: default;
    white-space: nowrap;
    border: 1px solid transparent;
}

thead {
    position: relative;
}

thead #name {
    position: relative;
}

.table-row-pointer a {
    cursor: pointer
}

.table-view table th:first-child,
.table-view table td:first-child {
    padding-left: 20px;
}

.table-view table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
}

.table-view td {
    font-size: 14px;
    font-weight: 500;
}

.table-view tbody tr {
    border-radius: 8px;
    background-color: #fff;
    border-spacing: 0 0.5em;
}

.table-view tbody {
    position: relative;
}

.table-view td:first-child {
    border-radius: 8px 0px 0px 8px;
}

.table-view td:last-child {
    border-radius: 0px 8px 8px 0px;
    /* min-width: 100px;
    max-width: 200px; */
}

.table-lisa-text {
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    color: #652F91;
}

.table-lisa-text.black {
    font-weight: 500;
    color: #3B4250;
}

@media screen and (max-width: 768px) {
    .table-view table {
        border: 0;
    }

    .table-view table caption {
        font-size: 1.3em;
    }

    .table-view table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .table-view table tr {
        display: block;
        margin-bottom: .625em;
    }

    .table-view table td {
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .table-view table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .table-view table td:last-child {
        border-bottom: 0;
    }
}

.table-button-blk {
    display: flex;
    justify-content: flex-end;
}

.th-small {
    width: 140px;
}

.th-medium {
    width: 220px;
}

.th-large {
    width: 340px;
}

@media(max-width: 768px) {
    .th-small {
        width: auto;
    }

    .th-medium {
        width: auto;
    }

    .th-large {
        width: auto;
    }
}


/* email-word-wrap */

.wrap-email {
    word-break: break-all;
    cursor: default !important;
}

.head-position {
    position: relative;
}

.child-div {
    background-image: url('../../../src/assets/images/dropdown_black.svg');
    background-size: cover;
    width: 10px;
    height: 6px;
    display: -webkit-inline-box;
    margin-left: 5px;
    cursor: pointer;
}

.requestreceived {
    position: revert !important;
    /* margin: 8px !important */
}

/* .name{
    right: 80px !important;
} */
.child-div-up {
    background-image: url('../../../src/assets/images/dropdown_black.svg');
    transform: rotate(180deg);
    display: -webkit-inline-box;
    margin-left: 5px;
    width: 10px;
    height: 6px;
    background-size: cover;
    cursor: pointer;
}


.fourtyeight-class {
    background-color: #d94e53;
    width: 30px;
    height: 100%;
    padding: 0px !important;
    text-align: center;
    color: #fff;
    font-size: 10px !important;
    align-content: center;
    border-radius: 4px 0px 0px 4px;

}

.updated-class {
    position: relative;
    background-color: #f7c310;
    width: 30px;
    height: 100%;
    padding: 0px !important;
    text-align: center;
    color: black;
    font-size: 10px !important;
    align-content: center;
    border-radius: 4px 0px 0px 4px;
}

.updated-class div {
    position: absolute;
    left: -6px;
}

.updated-class img {
    position: absolute;
    right: -17px;
    top: 1px;
}

.history-table-height {
    height: 300px;
    /* overflow: auto; */
}


.table-view table .unsolicited-form td {
    /* box-shadow: 0px 3px 0px rgb(226 192 255); */
}

.table-view tbody tr.unsolicited-form {
    background-color: #e4c8fc;
}

.result-text-wrap {
    width: 100px;
}

.no-tab-style {
    width: 43px;
}

.bg-archive {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: absolute;
    padding: 2px 0px 0px 10px !important;
    background-color: #EAEBED;
    margin-left: 11px;
}

.archive-checkbox {
    padding: 3px 0px 0px 20px;
}

.archive-checkbox.archive-head {
    width: 40px;
}

.archive-checkbox .container input:checked~.checkmark {
    background-color: white !important;
}

.archive-checkbox .checkmark {
    border: 2px solid #3B4250;
    background-color: white !important;
    height: 18px !important;
    width: 18px !important;
}

.archive-checkbox .checkmark:after {
    border: solid #3B4250 !important;
    background-color: white !important;
    border-width: 0 2px 2px 0 !important;
    left: 5px !important;
    top: 1px !important;
    width: 5px !important;
    height: 10px !important;
}

.archive-row .checkmark {
    background-color: white !important;
}

.archive-popup {
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 30px;
    z-index: 10;
    background-color: white !important;
}

.archive-popup div {
    border: 1px solid #DCDEE0;
}

thead .archive-checkbox .checkmark {
    top: 6px !important
}

.selected-archieve {
    border-radius: 10px;
    width: 100%;
    max-width: 160px;
    background-color: #EAEBED;
    text-align: center;
    position: absolute;
    top: 53px;
    left: 0;
    z-index: 10;
}

.selected-archieve span {
    font-weight: bold;
}

.archieve-icon {
    position: absolute;
    left: 65px;
    top: 24px;
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.check-top {
    top: 58px !important
}

.no-check-top {
    top: 50px !important
}

.top-pos {
    top: 24px;
    left: 65px;
}

.lilie-pos {
    top: 24px;
    left: 55px !important;
}

.archieve-pos {
    top: 24px !important;
    left: 65px;
}

.negative-pos {
    left: 58px;
}

.archived {
    outline: 1px solid black;
}

.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    border-radius: 24px;
    font-size: 18px;
}

.sort-blk {
    width: 100%;
    max-width: 230px;
    background: white;
    box-shadow: 6px 8px 14px rgb(0 0 0 / 10%);
    position: absolute;
    top: 50px;
    z-index: 30;
    border-radius: 16px;
}

.sort-blk .close-white-pos {
    top: 8px;
    right: 11px;
}

.sort-blk .login-btn {
    border-radius: 10px !important;
    font-size: 12px !important;
    font-weight: normal;
    padding: 0px 16px 0px 16px !important;
}

.sort-footer-blk {
    border-bottom: 1px solid #c9c1c1;
}

.btn-secondary input::after {
    color: #3B4250;
}

.footer-blk-btn {
    display: flex;
    justify-content: flex-end;
    margin: 15px;
}

.footer-blk-btn button {
    color: grey;
    cursor: pointer;
}


.radio_btns {
    display: flex;
    justify-content: left;
    align-items: center;
    color: grey;
    height: 30px;
}

.radio_btns [type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.radio_btns label {
    display: block;
    cursor: pointer;
    line-height: 2.5;
}

.radio_btns [type="radio"]+span {
    display: inline-block;
}

.radio_btns [type="radio"]+span:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: -0.25em;
    border-radius: 2rem;
    border: 1px solid grey;
    margin-right: 0.75em;
    transition: 0.5s ease all;
}

.radio_btns [type="radio"]:checked+span:before {
    background: white;
    box-shadow: 0 0 0 0.25em #652f91;
}

.radio_btns label {
    float: left;
    width: 150px;
}

.sort-label {
    font-size: 16px;
    color: grey;
    font-weight: normal;
}

.parent-radio {
    padding: 30px 20px 10px 20px;
}

.img-wrapper {
    position: relative;
    width: 100%;
    font-size: 0;
}

.img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.75);
}

.img-wrapper img {
    width: 100%;
}

.container .checkbox-base {
    margin-top: 17px;
}

.archive-row {
    padding-bottom: 0px !important;
}

.archieve-icon:hover+.sub-tool {
    padding: 3px 5px 3px 5px;
    background-color: black;
    border-radius: 4px;
    color: white;
    font-size: 10px;
    position: absolute;
    bottom: -17px;
    left: 52px;
    z-index: 20;
    display: block !important;
}

.archieve-icon:hover+.bottom-pos {
    bottom: -15px;
}

.archive-checkbox .checkbox-base input:disabled+.checkmark {
    border: 2px solid #c5c7c8;
    background-color: #f5f7f9 !important;
}