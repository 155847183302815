.approve-kit-btn {
  padding: 5px 15px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #5a9f00;
  border: 1px solid #5a9f00;
  border-radius: 20px;
  transition: all 0.1s linear;
}
.approve-kit-btn:hover {
  color: #fff;
  background-color: #5a9f00;
  border: 1px solid #5a9f00;
}
.approve-kit-btn:hover svg path{
  fill: #FFF;
}

.await-kit-btn {
  padding: 5px 15px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #c2af24;
  border: 1px solid #c2af24;
  border-radius: 20px;
  transition: all 0.1s linear;
}

.await-kit-btn span:nth-child(1){
  align-items: center;
}

.await-kit-btn:hover {
  color: #fff;
  background-color: #c2af24;
  border: 1px solid #c2af24;
}

.await-kit-btn svg path:nth-child(2){
  fill: #c2af24;
}

.await-kit-btn:hover svg path:nth-child(2){
  fill: #FFF;
}

.approve-kit-btn:hover {
  color: #fff;
  background-color: #5a9f00;
  border: 1px solid #5a9f00;
}
.approve-kit-btn:hover svg path{
  fill: #FFF;
}
.archive-kit-btn{
  padding: 5px 15px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #ffff;
  border: 1px solid #652F91;
  border-radius: 20px;
  background-color: #652F91;
  transition: all 0.1s linear;
}
.archive-kit-btn:hover {
  color: #fff;
  background-color: #652F91;
  border: 1px solid #652F91;
}
.w-row {
  max-width: 100%;
}
.approve-kit-btn.reject {
  color: #d60052;
  border: 1px solid #d60052;
  /* position: absolute; */
}
.approve-kit-btn.reject:hover {
  color: #fff;
  background-color: #d60052;
  border: 1px solid #d60052;
}

.approve-kit-btn.reject:disabled{
border: 1px solid #aaa !important;
pointer-events: none;
}

.approve-kit-btn.amend {
  color: #944cc5;
  border: 1px solid #944cc5;
}
.approve-kit-btn.amend:hover {
  color: #fff;
  background-color: #652f91;
  border: 1px solid #652f91;
}
.w-txt {
  height: 77px !important;
  max-width: 75% !important;
}
.f-size-app {
  font-size: 14px;
}
.f-b-app {
  font-weight: 700;
  margin-top: 30px;
}
.f-width {
  max-width: 50%;
}
.max-size {
  max-height: 500px;
  overflow: auto;
}
@media  (max-width:1024px) {
  .max-size {
    max-height: 300px;
    overflow: auto;
  }
}
.form-color {
  background-color: #e7e9eb;
  outline: none !important;
  box-shadow: none !important;
  max-width: 311px !important;
}
.form-color:focus {
  border-color: #e7e9eb !important;
  box-shadow: none !important;
  background-color: #e7e9eb;
}
.area-color {
  background-color: #e7e9eb;
  outline: none !important;
  box-shadow: none !important;
}
.m-top {
  margin-top: 44px;
}
.checkbox-label {
  font-weight: normal !important;
  margin-left: 20px !important;
  margin-top: 3px;
  white-space: break-spaces;
}
.select-form__control {
  border-color: #e7e9eb !important;
  background-color: #e7e9eb !important;
  max-width: 311px;
  height: 34px;
}
.select-form__input-container {
  height: 37px;
}
.select-form__control--is-focused {
  border: #e7e9eb;
  box-shadow: none;
  border-width: 0px;
}
.select-form__value-container {
  padding-left: 20px !important;
  align-items: flex-start !important;
}
/* .select-form__placeholder{
      padding-left: 15px;
  } */
.select-form__menu {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
  margin-top: -1px !important;
  max-width: 311px;
}
.select-form__option {
  padding: 15px 26px !important;
  font-weight: 600;
  cursor: pointer;
  max-width: 311px;
}

.select-form__option.select-form__option--is-focused {
  background-color: #f7be00;
}
.select-form__option.select-form__option--is-focused:hover {
  background-color: #f7be00 !important;
}
.select-form__option:hover {
  background-color: rgba(247, 190, 0, 0.1);
}
.select-form__option:active {
  background-color: #f7be00 !important;
}
.select-form__indicators {
  width: 100px;
}
.select-form__dropdown-indicator {
  display: none !important;
}
.arrow-icon-appr {
  height: 37px;
  width: 35px;
  background-color: #652f91;
  position: absolute;
  bottom: 0px;
  pointer-events: none;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
@media (max-width:768px) {
  .arrow-icon-appr {
    right: 0
  }
}
@media (min-width:768px) {
  .arrow-icon-appr {
    left: 275px;
  }
}
.pos-arrow-appr {
  position: absolute;
  top: 12px;
  left: 6px;
}
.btn-app-col {
  background-color: #5a9f00 !important;
}
.btn-app-col:hover {
  background-color: #5a9f00 !important;
}
.l-check {
  max-width: 340px;
}
.m-check {
  max-width: 235px;
}
.f-check {
  max-width: 155px;
}
.btn-rej-col {
  background-color: #c10248 !important;
}
.btn-rej-col:hover {
  background-color: #c10248 !important;
}

.staff-profile-table {
  position: relative;
}

.staff-profile-table thead {
  box-shadow: 0px 3px 0px rgb(0 0 0 / 10%);
}

.staff-profile-table .table-view {
  padding-top: 0px;
}

.staff-profile-table th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  height: 100%;
}
.staff-profile-table tr th:first-child,.staff-profile-table tr td:first-child{
  padding-left: 10px;
}