.btn-class {
    width: 84px;
    height: 50px;
    font-size: 14px;
    font-family: museosans;
    background-color: #81629b;
    border-color: #81629b;
    color: white;
    margin-left: 6px;
}

.main-btn-class {
    padding-top: 19px;
}

.width-btn {
    width: 111px !important;
}

@media (max-width: 356px) {
    .width-btn {
        margin-top: 5px;
    }
}

.postage-style {
    background-color: #582e7a;
    color: white;
    font-family: 'AlternateGothic', sans-serif;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 75px);
    height: 100%;
    position: relative;
}

.btn-style-next {
    padding-top: 20px;
}

.btn-big {
    width: 111px;
    height: 50px;
    font-size: 14px;
    background-color: #81629b;
    border-color: #81629b;
    color: white;
    margin-left: 6px;
    font-family: museosans;
}

@media (max-width: 356px) {
    .btn-big {
        margin-top: 6px;
    }
}

.p2-style {
    font-size: 15px;
    font-family: museosans;
}

.p1-style {
    letter-spacing: 0.62px;
    padding-top: 53px;
    font-size: 31px;
    font-family: museosans;
}

.p3-style {
    color: white;
}

.nav-class {
    height: 73px;
    /* margin-top: 41px;
    margin-bottom: 58px; */
}

.text-class {
    padding: 16px 30px 18px 30px;
    font-size: 26px;
    background-color: #81629b;
    border-color: #81629b;
}

@media (min-width:339px) and (max-width:361px){
    .text-class {
        padding-right: 9px !important;
    } 
}

.pad-te {
    padding-top: 30px;
}

.plus-i {
    margin-top: 16px;
    cursor: pointer !important;
}

.accordion {
    /* width: 100%; */
    padding: 30px 0px;
    /* max-width: 470px; */
}

.pad-bo {
    padding-bottom: 0px !important;
}

.panel {
    background-color: brown;
}

.panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    font-weight: 500;
    font-size: 17px;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;
}

.panel__content {
    /* margin: 5px 25px 25px; */
    font-size: 14px;
    transition: opacity 0.3s linear 0.18s;
    color: #decccc;
}

.panel:focus {
    outline: none;
}

.panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.footer-class {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 30px;
}