.preContact-style {
    background-color: #30BDBF;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.btn-main:disabled {
    background-color: #F7F7F7;
    color: black !important;
    border: none;
}

.dis-fl-none {
    display: inherit !important;
}

.pre-contact-content{
    font-size: 24px !important;
}

@media (max-width: 580px) {
    .pre-contact-content{
        font-size: 16px !important;
    }
}