.contact-add .approve-kit-btn {
    border: 1px solid #652f91 !important;
    color: #652f91 !important;
}

.contact-add .approve-kit-btn:hover {
    color: white !important;
    background-color: #652f91 !important;
}

.no-contact .approve-kit-btn {
    color: #d60052 !important;
    border: 1px solid #d60052 !important;
}

.no-contact .approve-kit-btn:hover {
    color: white !important;
    background-color: #d60052 !important;
}

.adjust-pos {
    display: flex;
    margin-left: 5px;
    margin-top: 0px;
}

.unable_contact_icon {
    margin-top: 2px;
}

.response_icon{
    margin-top: -1px;
}

.like_svg {
    margin-top: 2px;
}


.parent-contact .react-datepicker__current-month{
    background-color: #fff !important
}

.parent-contact .react-datepicker__navigation{
    color: black !important;
}

.parent-contact  .react-datepicker__month-container {
    border-width: 1px;
    border: 1px solid #d6d4d4 !important
}