.dateofbirth-style {
    background-color: #30bdbf;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.single-row {
    display: flex;
    flex-direction: row;
}

.dateofbirth-head {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.mar-ft-btn {
    margin-top: 40px;
}

.f-sty {
    font-style: normal !important;
}

.invalid-date-x-icon{
    padding-left: 260px;
    padding-top: 17px;
    padding-right: 13px;
}

.consent-blk {
    width:100%
}
