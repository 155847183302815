.accordion-wrap {
  @apply block;
}

.accordion-head {
  @apply mb-1.5 py-5 relative cursor-pointer;
}

.accordion-head .acc-up-arrow {
  @apply absolute right-2.5	top-6	border-solid border-primary-100 border-r-4 border-b-4	inline-block p-1;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.accordion-head .acc-down-arrow {
  @apply absolute right-2.5	top-6	border-solid border-primary-100 border-r-4 border-b-4	inline-block p-1;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.accordion-content {
  @apply mb-2;
}
