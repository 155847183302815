header {
  @apply bg-card-100;
}
.welcome-text {
  @apply text-2xl font-thin;
}

.welcome-para {
  @apply text-xl uppercase max-w-md;
}

@media (max-width: 576px) {
  .sm-block button {
    display: block;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
