.lilie-block {
  display: flex;
  /* grid-template-columns: 1frx; */
  gap: 20px;
  align-items: center;
}

.sync-block {
  grid-template-columns: 160px 1fr;
}

.lilie-block span {
  @apply cursor-pointer mr-5;
  color: #652f91;
  width: 100%;
  max-width: 120px;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.lilie-input-blk {
  position: relative;
  display: inline-block;
  width: 160px;
  border: 1px solid #757778;
}

.lilie-input-blk input:focus {
  outline: none;
}

.lilie-input {
  width: 100%;
  max-width: calc(100% - 50px);
  padding: 8px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}

.lilie-add-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  color: #652f91;
  outline: none;
  padding: 0 10px;
  z-index: 2;
}

.lilie-id-modal .close-white {
  width: 15px;
  height: 15px;
}

.lilie-id-modal .success-icon {
  width: 32px;
  height: 32px;
  background-color: #37A590;
  border-radius: 50px;
  margin-right: 16px;
  display: flex;
}

.lilie-id-modal .contact-attempts-block p {
  font-size: 18px;
  font-weight: 400;
  color: #3B4250;
}

.lilie-id-modal .contact-attempts-block {
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
}

.sync-btn {
  font-size: 16px;
  background-color: #fff !important;
  color: #652f91;
  border: 1px solid #652f91;
  padding: 10px;
  border-radius: 25px;
}
