.record-status {
  @apply p-6 bg-card-200 text-center rounded-md;
}

.record-status img {
  @apply mb-2 inline-block;
}

.record-status p {
  @apply text-base;
  font-weight: 400;
}
