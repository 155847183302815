.font-check {
  font-family: museosans;
  font-size: 26px;
}

.form-check-input[type='checkbox'] {
  border-radius: 4px !important;
}

.pos-re {
  /* position: relative; */
  top: 8px;
}

.form-check-input:checked {
  border-color: none !important;
  border: none !important;
}
.form-check-input[type='checkbox']:focus-visible {
  outline: #101010 auto 2px !important;
  outline-offset: 8px;
}

.label-font {
  font-size: 16px !important;
}

.p4-style {
  font-size: 16px;
  font-family: museosans !important;
  color: black;
  width: 80%;
  padding-bottom: 40%;
  font-weight: normal !important;
}

.p5-style {
  font-size: 16px;
  font-family: museosans !important;
  color: black;
  font-weight: normal !important;
  margin-bottom: 30px;
}

.p4-style-title {
  font-size: 16px;
  font-family: museosans;
  color: black;
  width: 82%;
  font-weight: bold;
}

.x-icon {
  padding-left: 260px;
  padding-top: 17px;
}
.closeBtn {
  padding-left: 260px;
  padding-top: 17px;
}
.popup-info-section {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
}
.info-popup {
  min-width: 300px;
  min-height: 262px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  border-radius: 22px;
  flex-direction: column;
  margin: auto;
  box-shadow: 0px 10px 6px #00000029;
}

.sex-style {
  background-color: #582e7a;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
}

.genital-style {
  background-color: #582e7a;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
}

/* @media (min-width: 500px) {
    .genital-style {
        height: calc(100vh - 70px);
    }
} */

.mar-tat {
  margin-top: 25px;
}

.sub-button {
  border-radius: 5px;
  width: 100px;
  height: 50px;
  background-color: #7d6598;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.sub-button:hover {
  color: inherit;
}
.sub-button:active {
  -webkit-box-shadow: none !important;
  outline: none;
  /* color: black; */
  background-color: white !important;
}

.sub-button:focus {
  /* outline: none !important; */
  box-shadow: none !important;
}

.mar-sub {
  margin-left: 38px;
  font-size: 16px !important;
}

.mar-pos-t {
  margin-top: 3rem;
}

.after-active:focus {
  background-color: #7d6598 !important;
  /* color: white  !important; */
}
