.perm-mgmt-block {
  display: grid;
  grid-template-columns: 270px auto;
  grid-column: 20;
  min-height: calc(100vh - 260px);
}

.perm-mgmt-menus-list {
  display: flex;
  flex-direction: column;
}

.perm-mgmt-menus-list a {
  padding: 2rem 1rem;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #3B4250;
}

.perm-mgmt-menus-list a:hover {
  padding: 2rem 1rem;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #3B4250;
}

.perm-mgmt-content-blk {
  background-color: #FFF;
  padding: 2rem;
}

.perm-mgmt-content-blk.archive-blk .rdt_TableHeadRow .rdt_TableCol:last-child .rdt_TableCol_Sortable {
  margin-left: 10px !important;
}

.perm-mgmt-menus-list .active {
  color: #652F91;
  background-color: #FFF;
}

.role-title {
  font-size: 24px;
  font-weight: 600;
  color: #3B4250;
  margin-bottom: 20px;
}

.count-label {
  display: inline-block;
  width: 30px;
}

.edit-label {
  color: #652F91;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.team-member-blk {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.team-member-blk button {
  padding: 10px 20px !important;
  display: flex;
  align-items: center;
}

.team-member-blk button svg {
  margin-right: 10px;
}

.name-column {
  color: #3B4250;
  font-size: 16px;
  cursor: pointer;
}

/************************************************************************
                    Add team member form
*************************************************************************/
.team-member-label {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.team-member-form {
  padding: 3rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 850px;
  width: 100%;
}

.team-member-field-blk {
  display: grid;
  grid-template-columns: 250px auto;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
}

.team-member-field-blk:last-child {
  margin-bottom: 0;
}

.team-member-field-blk .input-group .text-field {
  min-height: 60px;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background-color: #FFF !important;
}

.team-member-field-blk .input-group input {
  font-family: 'MuseoSans', sans-serif !important;
  font-size: 16px !important;
}

.team-member-field-blk .input-group input::placeholder {
  color: #707070;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal !important;
}

.team-member-btn {
  display: flex;
  justify-content: flex-end;
}

.team-member-btn button:first-child {
  margin-right: 20px;
}

.form-message-blk {
  display: inline-flex;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 32px;
}

.add-team-success {
  background-color: rgba(140, 198, 63, 0.2);
  border: 2px solid #8CC63F;
}

.add-team-error {
  background-color: rgb(198 63 63 / 20%);
  border: 2px solid #c6593f;
}

.add-team-error {
  background-color: rgba(255, 186, 186, 0.2);
  border: 2px solid #FFBABA;
}

.team-member-form .error-message {
  color: #D8000C
}

.form-message-blk .msg {
  font-size: 16px;
  margin-right: 20px;
}

.form-message-blk .close-icon {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-icon svg {
  width: 12px;
  height: 12px;
}

.btn-disable {
  background-color: #EBEBEB !important;
  color: #757778 !important;
  cursor: default !important;
}

.team-member-btn.btn.btn-primary:focus,
.cancel-button {
  background-color: #FFF !important;
  color: #652F91 !important;
  border: 1px solid #cdcdcd !important;
}

/************************************************************************
                    End of add team member form
*************************************************************************/


/************************************************************************
                    Permission management modal
*************************************************************************/
.perm-modal .modal-content.model-bor {
  border-radius: 10px !important;
}

.perm-modal .modal-dialog {
  max-width: 550px !important;
}

.perm-modal .modal-header {
  padding: 24px 32px;
}

.perm-modal .modal-footer {
  padding: 24px 16px !important;
}

.perm-modal .modal-footer .login-btn {
  padding: 5px 15px !important;
  font-size: 14px !important;
  margin: 0;
}

.perm-modal .modal-footer button:first-child {
  margin-right: 20px;
}

.perm-modal .popup-text {
  margin: 0;
  font-size: 16px;
}

.perm-modal hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cdcdcd;
  margin: 0;
  padding: 0;
}

/************************************************************************
                    End of permission management modal
*************************************************************************/


/************************************************************************
                    Edit permission management
*************************************************************************/
.ed-name-blk {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
}

.user-name {
  font-size: 28px;
  font-weight: 700;
  color: #3B4250;
  text-transform: capitalize;
}

.role-name {
  font-size: 18px;
  font-weight: 400;
  color: #757778;
}

.edit-perm-label {
  font-size: 28px;
  color: #3B4250;
  font-weight: 600;
}

.name-blk {}

.drop-down-section {
  max-width: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-perm-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.manage-grid-label {
  font-size: 24px;
  font-weight: 600;
  color: #3B4250;
  /* margin-bottom: 40px; */
}

.manage-grid-blk {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  column-gap: 50px;
  row-gap: 30px;
}

.manage-card-section {
  padding: 3rem;
  background-color: #fcfcfc;
  border-radius: 10px;
}

.manage-grid-item {
  padding: 32px 24px;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  background-color: #FFF;
  display: flex;
}

.manage-grid-item .custom-checkbox {
  max-width: 50px;
  height: 50px;
  width: 100%;
}

.manage-grid-item .checkmark {
  width: 35px;
  height: 35px;
  top: 15px;
}

.manage-grid-item .check-label .checkmark::after {
  width: 8px !important;
  height: 20px !important;
  left: 12px !important;
}

.manage-grid-item .manage-name {
  font-weight: 400;
  font-size: 16px;
  color: #3B4250;
  margin: auto 0;
}

.no-data-card {
  display: flex;
  border: 1px solid #cdcdcd;
  min-height: 250px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

/************************************************************************
                    End of edit permission management
*************************************************************************/