.dropdown-cascade {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  max-width: 50px;
  border-radius: 50px;
  /* border: 1px solid #3B4250; */
}

/*
.dropdown-cascade:hover {
  background-color: #F2F2F2;
} */

/* .dropdown-cascade-blk .customstyle {
  border-radius: 50px;
  background-color: #F2F2F2;
  width: 32px;
  height: 32px;
} */

.dropdown-cascade-blk .customstyle .show {
  background-color: #FFF;
  border-radius: 10px;
  min-width: 240px;
  animation: fadeInShow .3s;
  display: block !important;
  font-family: 'MuseoSans', sans-serif;
  /* box-shadow: 2px 4px 20px rgba(205, 205, 205, 0.1) !important; */
}

.dropdown-cascade-blk .customstyle .show li {
  margin: 0;
}

.dropdown-cascade-blk .Cascade-module_dropdownMenuItem__2z87N {
  font-size: 16px;
  color: #3B4250;
  font-weight: bold;
  padding: 10px 25px !important;
}

.Cascade-module_dropdownMenuItem__2z87N:hover>.Cascade-module_dropdownMenu__19WmY {
  animation: Cascade-module_fadeInShow__1OBww .3s;
  display: block;
  min-width: 140px;
  border-radius: 10px;
  margin-left: 1px;
  margin-top: 10px;
  background-image: linear-gradient(to right, transparent 50px, #FFF 90%);
}

.dropdown-cascade input[type='text'] {
  opacity: 0;
  cursor: pointer;
  /* width: 32px;
  height: 32px; */
  font-size: 0;
  position: absolute;
}

.customInput {
  display: flex;
  justify-content: center;
}

.dropdown-cascade .input-group .customInput {
  margin: 0;
  display: flex;
  padding: 5px;
  border-radius: 50px;
  /* margin-left: 20px; */
  cursor: pointer;
}

.dropdown-cascade .input-group .customInput svg {
  width: 22px;
  height: 22px;
}

.dropdown-cascade-blk ul li ul {
  min-height: 0 !important;
}

.menu-icon-active .dropdown-cascade .input-group .customInput {
  background-color: #F2F2F2;
}

.dropdown-cascade-blk.bgActive label,
.dropdown-cascade label:hover,
.dropdown-cascade label:active,
.dropdown-cascade label:focus {
  background-color: #F2F2F2 !important;
}