.mobilenumber-style {
    background-color: #30bdbf;
    color: white;
    min-height: calc(100vh - 75px);
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mob-number-prefix {
    max-width: 70px;
}

.mobNumber {
    margin-left: 20px;
}

.mob-number {
    display: flex;
    flex-direction: row;
}

.fo-pos {
    font-style: normal !important;
    font-size: 17px !important;
    font-family: museosans;
}

@media (min-width:1025px) {
    .fo-pos {
        margin-top: 9px;
    }
}