.star-rating__checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .star-rating__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;
    color: #ababab;
    transition: color 0.2s ease-out;
  }
  .star-rating__star:hover {
    cursor: pointer;
  }
  .star-rating__star.is-selected {
    color: #ffd700;
  }
  .star-rating__star.is-disabled:hover {
    cursor: default;
  }
  .star-rating{
      font-size: 30px;
  }
  .m-desc-top{
    margin-top: 45px;
  }