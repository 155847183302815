.tri-nav-wrap {
    position: relative;
    display: block;
    padding-top: 10px;
    margin-left: -11px;
}

.tri-nav-blk {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
}

.tri-nav-blk a {
    padding: 16px;
    font-size: 18px;
    font-weight: 500;
    color: #652F91;
    color: #3B4250;
    text-decoration: none;
    outline-offset: 0px;
}

.tri-nav-blk a:hover {
    text-decoration: none;
}

.tri-nav-blk a.active {
    color: #652F91;
    border-bottom: 3px solid #652F91;
}

.tri-nav-wrap hr {
    margin: -3px 0px 0px 0px;
    height: 2px;
    border-color: #D8DFE6;
}

.tri-nav-wrap .right-content {
    position: absolute;
    top: 10px;
    right: 0;
}

/* StaticTriNav Css */

.tri-nav-blk div {
    padding: 16px;
    font-size: 18px;
    font-weight: 700;
    color: #652F91;
    color: #3B4250;
    text-decoration: none;
    cursor: pointer;
}

.tri-nav-blk div:hover {
    text-decoration: none;
}

.tri-nav-blk div.active {
    color: #652F91;
    border-bottom: 3px solid #652F91;
}

.archive-btn {
    position: absolute;
    right: 0;
}

.archive-sub {
    padding: 1px 16px 0px 17px !important;
    color: #d60052;
    border: 1px solid #d60052;
    position: relative !important;
}

/* *************** Notification badge ************************ */
.notification {
    text-decoration: none;
    position: relative;
    display: inline-block;
    border-radius: 2px;
}

.notification .badge {
    position: absolute;
    top: 0;
    right: -10px;
    border-radius: 50%;
    background-color: #652F91;
    color: #FFF;
    cursor: default;
    width: 25px;
    height: 25px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* *************** End of notification badge ************************ */

/* *************** Multiple order function count ************************ */
.name-column-blk {
    display: grid;
    grid-template-columns: 50px 1fr;
    column-gap: 10px;
}

.mof-count-blk {
    display: flex;
    background-color: #31BDBF;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.mof-count {
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
}

/* *************** End of Multiple order function count ************************ */