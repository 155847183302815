.overview-card-blk {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 30px;
  padding-right: 30px;
}

.menu-card-container {
  display: flex;
  justify-content: flex-end;
}

.menu-card-container .menu-card-blk:first-child {
  margin-right: 35px;
}

.h-30 {
  height: 30px;
}

.menu-card-blk {
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  width: 100%;
  max-width: 350px;
}

.sub-menu-card-blk {
  display: flex;
  padding: 16px;
  align-items: center;
}

.menu-card-left {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.action-blk {
  display: flex;
  width: 100%;
  max-width: 80px;
  justify-content: flex-start;
  border-left: 1px solid #cdcdcd;
  height: 55px;
  padding-left: 16px;
}

.day-text {
  font-size: 30px;
  font-weight: 600;
  color: #3b4250;
}

.date-range {
  font-size: 22px;
  font-weight: 600;
  color: #3b4250;
  margin-right: 35px;
}

.date-range-label {
  color: #652f91;
  font-size: 14px;
  font-weight: 400;
}

.date-text {
  display: inline-block;
  color: #652f91;
  font-size: 20px;
  font-weight: 600;
}

.result-menu-label {
  font-size: 20px;
  font-weight: 600;
  color: #3b4250;
}

.result-submenu-label {
  font-size: 14px;
  color: #3b4250;
  font-weight: 400;
}

.total-count {
  font-size: 45px;
  font-weight: 600;
  margin-right: 12px;
}

.requests {
  background-color: #652f91;
}

.flagged-result {
  background-color: #ff6700;
}

.received {
  background-color: #e44498;
}

.positive-result {
  background-color: #31bdbf;
}

.positive-result-count {
  color: #31bdbf;
}

.flagged-result-count {
  color: #ff6700;
}

.view-all-text {
  color: #582e7a;
  font-size: 16px;
  font-weight: 400;
}

/* Filter section */
.filter-blk {
  background-color: #fff;
  padding: 40px 30px;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
}

.filter-section {
  display: grid;
  grid-template-columns: 1fr 180px 360px 180px;
  gap: 20px;
}

.graph-header-text {
  color: #757778;
  font-size: 16px;
  margin: 20px 0;
}

.power-bi {
  width: 180px;
  height: 60px;
}

.result-blk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.sti-count-blk {
  padding-top: 40px;
}

.sti-count-blk .result-menu-blk {
  grid-template-columns: auto auto auto auto !important;
}

.sti-card-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.result-menu-blk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.result-menu-items-blk {
  padding: 22px;
  border-radius: 10px;
  cursor: pointer;
  height: 200px;
  min-width: 250px;
}

.result-menu-title {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.result-menu-list-label {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
}

.result-count {
  font-size: 80px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}

.date-subRange-blk {
  display: flex;
  align-items: center;
}

.arrow-icon-blk {
  display: flex;
  width: 100%;
  max-width: 50px;
  justify-content: space-between;
}

.arrow-icon-blk button svg {
  width: 22px;
  height: 22px;
}

/* End of filter section */

.stis-blk {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(3, minmax(250px, 400px));
  gap: 30px;
}

.stis-menu-item {
  background-color: #fff;
  border-radius: 10px;
  padding: 22px 30px;
  border: 1px solid #cdcdcd;
}

.test-kits-blk {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.test-kit-heading {
  color: #3b4250;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}

.test-kits-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.stis-label {
  font-size: 14px;
  color: #3b4250;
  font-weight: 400;
  margin-bottom: 24px;
}

.stis-title {
  color: #582e7a;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 50px;
}

.stis-kit-card-value {
  color: #582e7a;
  font-size: 20px;
  font-weight: 600;
}

.deactivate-sti textarea {
  width: 100%;
  max-width: 900px;
  color: black;
  background-color: white;
  outline: 1px solid black !important;
  font-size: 17px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 180px !important;
  font-weight: 400;
}

.deactivate-sti button {
  border-radius: 10px !important;
}

.chart-section {
  width: 100%;
  min-width: 300px;
}

.requests-shadow {
  box-shadow: 2px 4px 20px #8258a6;
}

.flagged-result-shadow {
  box-shadow: 2px 4px 20px #f1873f;
}

.received-shadow {
  box-shadow: 2px 4px 20px #e44498;
}

.positive-result-shadow {
  box-shadow: 2px 4px 20px #31bdbf;
}

.forward-arrow-icon:disabled {
  background-color: transparent;
  border: transparent;
  cursor: not-allowed;
}

.forward-arrow-icon:disabled svg {
  fill: #ddd;
}

.remove-column {
  grid-template-columns: 1fr 180px !important;
}

@media (max-width: 1300px) {
  .sti-count-blk .result-menu-blk {
    grid-template-columns: auto auto auto !important;
  }
}

@media (max-width: 900px) {
  .sti-count-blk .result-menu-blk {
    grid-template-columns: auto auto !important;
  }
}

@media (max-width: 768px) {
  .filter-section,
  .overview-card-blk,
  .result-blk {
    grid-template-columns: 1fr;
  }

  .result-menu-blk {
  }

  .stis-blk {
    grid-template-columns: repeat(2, 1fr);
  }
}


.deactivate-sti textarea::placeholder {
  color: #757778;
  opacity: 1; /* Firefox */
}

.deactivate-sti textarea::-ms-input-placeholder { /* Edge 12 -18 */
  color: #757778;
}