/* .pad-70{
    padding: 70px;
} */

@media (min-width: 768px) {
  .equal-space-padding {
    padding: 40px;
  }
}
@media (max-width: 768px) {
  .equal-space-padding {
    padding: 24px;
  }
}

.pad-20 {
  padding: 20px;
}
.pad-10 {
  padding: 10px;
}
.spacer-5 {
  width: 100%;
  height: 5px;
}
.spacer-10 {
  width: 100%;
  height: 10px;
}
.spacer-20 {
  width: 100%;
  height: 20px;
}
.spacer-30 {
  width: 100%;
  height: 30px;
}
.spacer-40 {
  width: 100%;
  height: 40px;
}
.signposting-page {
  font-family: 'MuseoSans', sans-serif;
  background-color: #fff;
}
.signpost-nav-bar {
  display: flex;
  padding: 16px 30px;
  height: 70px;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
}
.signposting-section {
  width: 100%;
}
.signposting-section .content-container {
  max-width: 1440px;
  margin: 0 auto;
  width: 90%;
}
.signposting-section .content-container .container-540 {
  max-width: 540px;
  margin: 0 auto;
}

.signposting-section .content-container .container-800 {
  max-width: 800px;
  margin: 0 auto;
}

.signposting-section .content-container .container-600 {
  max-width: 600px;
  margin: 0 auto;
}

.signposting-section .content-container .container-680 {
  max-width: 685px;
  margin: 0 auto;
}

.content-container button {
  margin-bottom: 15px !important;
}
.content-container button:last-child {
  margin-bottom: 0px;
}
.content-container-home button:last-child {
  margin-bottom: 15px !important;
}
.content-container.footer {
  padding: 35px 35px;
}
@media (max-width: 1024px) {
  .content-container.footer {
    padding: 40px;
  }
}
.signposting-section.bg-purple {
  background-color: #652f91;
}
.signposting-section.bg-yellow {
  background-color: #f8c415;
}
.signposting-section.bg-red {
  background-color: #d3373f;
}
.signposting-section.bg-orange {
  background-color: #ff6929;
}
.signposting-section.bg-blue {
  background-color: #6ac5ee;
}
.signposting-section.bg-pink {
  background-color: #d25396;
}
.signposting-section.bg-green {
  background-color: #7fc85b;
}
.signposting-section.bg-white {
  background-color: #fff;
}
.signposting-section.grey {
  background-color: #f4f2f4;
}
.signposting-section.color-black {
  color: #232323;
}
.signposting-section.color-white {
  color: #ffffff;
}
.signposting-section.color-purple {
  color: #652f91;
}

.signpost-navblk {
  display: flex;
  flex-wrap: nowrap;
  background-color: #652f91;
}
.center-div {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.signpost-navlink {
  color: white !important;
  text-decoration: none !important;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 22px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.signpost-navlink:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration: none;
}
.signpost-navlink.active {
  color: #0b0b0b !important;
  background-color: #f8c415;
}
.signpost-navlink.active:hover {
  color: rgba(11, 11, 11, 0.6) !important;
}

.content-button {
  min-height: 47px !important;
  border-radius: 10px !important;
  font-size: 16px;
  background-color: #f8c415 !important;
  color: black !important;
  width: -webkit-fill-available !important;
  white-space: inherit;
  padding: 10px 28px 10px 29px !important;
}

.content-button:focus {
  background-color: #f8c415 !important;
}

.s-p-btn {
  padding: 30px;
}

.s-first-btn {
  margin-top: 22px;
}

.dotted-border {
  border-bottom-color: 2px solid black dotted;
  border-bottom: dotted;
  color: #fbfbfb;
}
.dotted-border.purple {
  color: #5f2f86;
}
.dotted-border.dark {
  color: black;
}
.ask-link {
  font-size: 17px;
  font-weight: 700px;
  color: #ffde26 !important;
  text-decoration: underline;
}
.ask-link:hover {
  color: #ffde26 !important;
  text-decoration: none;
}
.s-m-portion {
  margin: 24px 30px 0px 30px;
}

.ma-s {
  margin: 30px;
}

.s-help {
  font-size: 43px;
}

.s-body {
  background-color: #fc601a;
  color: white;
  text-align: center;
  font-size: 23px;
  padding: 0px 5px 0px 5px;
  letter-spacing: 0.4px;
  position: absolute;
  font-family: 'AlternateGothic', sans-serif;
}

.s-pos-re {
  position: relative;
  background-color: #652f91;
}

.s-science {
  border: 3px solid #fc601a;
  text-align: center;
  height: 79px;
  font-size: 17px;
  font-weight: 700;
  padding-top: 20px;
  border-radius: 25px;
  margin-top: 25px;
  color: white;
}

.ok-btns {
  margin-top: 24px;
}

.s-btn-clr {
  padding: 13px 25px;
  width: auto;
  font-size: 16px;
  font-weight: 500;
  border-radius: 26px;
  background-color: #652f91;
  border: none;
  background-color: #f8c415 !important;
  color: black !important;
  text-decoration: none !important;
  font-family: museosans;
}

@media (max-width: 568px) {
  .s-btn-clr {
    width: 100%;
  }
}

.s-btn-clr.pink {
  background-color: #c93488 !important;
  color: white !important;
}
.s-btn-clr.orange {
  background-color: #fc601a !important;
  color: #fff !important;
}
.s-btn-clr.blue {
  background-color: #008fbf !important;
  color: #fff !important;
}
.s-btn-clr.green {
  background-color: #329632 !important;
  color: #fff !important;
}
.pink-btn-class {
  padding: 13px 25px;
  width: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 26px;
  background-color: #fbe3ed;
  border: none;
  color: #0b0b0b !important;
  text-decoration: none !important;
}
.true-border.blue {
  border: 3px solid #008fbf;
}
.true-border.orange {
  border: 3px solid #fc601a;
}
.true-border.pink {
  border: 3px solid #c93488;
}
.true-border.green {
  border: 3px solid #329632;
}
.false-border.blue {
  border: 3px solid #008fbf;
}
.false-border.orange {
  border: 3px solid #fc601a;
}
.false-border.pink {
  border: 3px solid #c93488;
}
.false-border.green {
  border: 3px solid #329632;
}
.s-think {
  font-size: 17px;
  color: white;
}

.mar-s {
  margin-top: 10px;
}

.zig-zag-pattern {
  background-repeat: repeat-x;
  width: 100%;
  padding: 0px 0px 13px 0px;
}
.zig-zag-pattern.color-purple {
  background-image: url('../../assets/images/signposting-arrow-purple.svg');
  background-color: #f4f2f4;
}
.zig-zag-pattern.sti {
  background-image: url('../../assets/images/signposting-arrow-purple.svg');
  background-color: #f4f2f4;
}
.zig-zag-pattern.color-white {
  background-image: url('../../assets/images/signposting-arrow-white.svg');
}
.zig-zag-pattern.bg-red {
  background-color: #d3373f;
}
.zig-zag-pattern.bg-purple {
  background-color: #652f91;
}
.fo-col {
  background-color: #652f91;
}

.s-quick {
  margin-top: 40px;
}

.bor-rad {
  border-radius: 60px !important;
}

.s-top {
  padding-top: 40px;
}

.sub-footer-col {
  color: white;
}

.pad-s-bot {
  padding-bottom: 40px;
}

.title-bg {
  min-height: 180px;
  padding: 20px;
}

.h-help {
  min-height: 220px !important;
}
.myth-chat-bubble {
  padding: 25px;
  color: #101010;
  font-size: 17px;
  font-weight: 700;
  background-color: #fff;
  border: 3px solid #fc601a;
  border-radius: 25px;
}
.section-head {
  font-family: 'AlternateGothic', sans-serif;
  font-size: 44px;
  letter-spacing: 1.2px;
  color: #fff;
}
.section-head.dark {
  color: #232323;
}
.section-head.purple {
  color: #652f91;
}
.section-head.orange {
  color: #fc601a;
  letter-spacing: 0px;
}
.section-head.blue {
  color: #008fbf;
  letter-spacing: 0px;
}
.section-head.pink {
  color: #c93488;
  letter-spacing: 0px;
}
.section-head.green {
  color: #329632;
  letter-spacing: 0px;
}
.sub-header {
  font-family: 'AlternateGothic', sans-serif;
  font-size: 36px;
  letter-spacing: 1.2px;
}
.sub-header.purple {
  color: #652f91;
}
.sub-header.small {
  font-size: 28px;
}
.sub-header.footer {
  font-size: 80px;
  line-height: 75px;
  letter-spacing: -1.32px;
}
.sub-header.heading {
  font-size: 30px;
}
@media (max-width: 1200px) {
  .sub-header.footer {
    font-size: 60px;
    line-height: 65px;
  }
}
@media (max-width: 812px) {
  .sub-header.footer {
    font-size: 35px;
    line-height: 40px;
  }
}
.section-desc {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
}
.section-desc.light {
  font-weight: 500;
}
.true-false-blk {
  margin: 0 auto;
  padding: 20px 20px;
  max-width: 260px;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

/* Footer styles */
.footer-blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.footer-blocks .footer-blk:nth-child(n) {
  position: relative;
}
.footer-blocks .footer-blk:nth-child(1) {
  width: 34%;
  padding-right: 8%;
}
.footer-blocks .footer-blk:nth-child(2) {
  width: 25%;
  padding-right: 5%;
}
.footer-blocks .footer-blk:nth-child(3) {
  width: 25%;
  padding-right: 5%;
}
.footer-blocks .footer-blk:nth-child(4) {
  display: none;
}
.footer-blocks .footer-blk:nth-child(5) {
  width: 14%;
  padding-right: 1%;
}
@media (max-width: 1200px) {
  .footer-blocks {
    flex-wrap: wrap;
  }
  .footer-blocks .footer-blk:nth-child(n) {
    width: 50%;
  }
  .footer-blocks .footer-blk:nth-child(1) {
    order: 1;
    padding-bottom: 20px;
  }
  .footer-blocks .footer-blk:nth-child(2) {
    margin-top: 60px;
    order: 3;
  }
  .footer-blocks .footer-blk:nth-child(3) {
    margin-top: 60px;
    display: block;
    order: 4;
  }
  .footer-blocks .footer-blk:nth-child(4) {
    display: none;
    order: 5;
  }
  .footer-blocks .footer-blk:nth-child(5) {
    -webkit-box-ordinal-group: 2;
    order: 2;
  }
}
@media (max-width: 812px) {
  .footer-blocks .footer-blk:nth-child(n) {
    width: 100%;
  }
  .footer-blocks .footer-blk:nth-child(1) {
    order: 1;
  }
  .footer-blocks .footer-blk:nth-child(2) {
    display: none;
    order: 4;
  }
  .footer-blocks .footer-blk:nth-child(3) {
    display: none;
    order: 5;
  }
  .footer-blocks .footer-blk:nth-child(4) {
    margin-top: 35px;
    display: block;
    order: 2;
  }
  .footer-blocks .footer-blk:nth-child(5) {
    margin-top: 35px;
    order: 3;
  }
}
.footer-links ul {
  list-style-type: none;
}
.footer-links ul li {
  margin: 0px;
}
.footer-links a {
  display: inline-block;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.2px;
  transition: all 0.4s ease;
  color: white !important;
}
.footer-links a:hover {
  text-decoration: none;
  opacity: 0.6;
}
.footer-logo {
  max-width: 143px;
  width: 143px;
}
.footer-social-links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

@media (max-width: 568px) {
  .footer-social-links {
    flex-wrap: unset !important;
  }
}

.footer-social-links a {
  margin: 0px 14px 14px 0px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50px;
  transition: all 0.4s ease;
}
.footer-social-links a:hover {
  background-color: #472563;
}
.footer-social-links a img {
  max-width: 23px;
}
.footer-social-links path,
.footer-social-links rect {
  transition: all 0.4s ease;
}
.footer-social-links a:hover path {
  fill: #fff;
}
.footer-social-links a:hover #Group_4259 path:last-child {
  fill: #652f91;
}
.footer-social-links a:hover rect {
  fill: #fff;
}
.copyright {
  position: absolute;
  bottom: -15px;
  font-size: 18px;
  color: #fff;
  opacity: 0.5;
}
.footer-link-type2 {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 30px;
  font-size: 16px;
  opacity: 0.6;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  transition: all 0.4s, -webkit-transform 0.4s;
}
.footer-link-type2:hover {
  padding-right: 40px;
}
.footer-link-type2::after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  background: url(../../assets/images/footer-arrow.svg) no-repeat center / cover;
  width: 15px;
  height: 12px;
}
.footer-link-type2 a {
  margin: 0px;
}
.footer-link-type2 a:hover {
  opacity: 1;
}
.pattern-placement {
  position: relative;
}
.pattern-placement .top-right {
  position: absolute;
  top: -15px;
  right: -38px;
}
.sec-desc-2-color {
  color: black !important;
}
.sec-desc-2-color p {
  font-size: 16px;
}
.sec-desc-2-color.white {
  color: white !important;
}
.bubble-box {
  background-color: #329632;
  color: white !important;
}
.chat-bubble-border {
  border: 3px solid #329632 !important;
}
.light-violet {
  border: 3px solid #008fbf !important;
}
.bg-bubble {
  background-color: #008fbf !important;
}
.bg-bubble-red {
  background-color: #fc601a !important;
}
.bg-bubble-pink {
  background-color: #c93488 !important;
}
.bg-bubble-green {
  background-color: #329632 !important;
}

.add-color {
  color: black;
}

.text-decoration-home {
  text-decoration: none !important;
}
.exitBtn {
  padding-bottom: 74px;
}

/*calender css */
.calendar-container {
  width: 240px;
  font-size: 12px;
  margin: 0 auto;
  box-shadow: 0 0 5px #8798ad;
  border-radius: 5px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background-color: #8545b9;
  color: #ffffff;
  font-weight: bold;
}

.calendar-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.calendar-month {
  margin: 0;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}
.calendar-grid button:focus-visible {
  outline-offset: 0px !important;
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin: auto;
  background-color: #fff;
  /* border: 1px solid #e0e0e0; */
  cursor: pointer;
}
.calendar-day:hover {
  background-color: #8545b9;
  border-radius: 20px;
  color: #ffffff;
}
.calendar-day.empty {
  /* background-color: #f0f0f0; */
  cursor: default;
}

.calendar-day.today {
  background-color: #8545b9;
  font-weight: bold;
  opacity: 0.8;
  color: #ffffff;
  border-radius: 20px;
}

.calendar-day:focus {
  outline: 2px solid #8545b9;
}

.calendar-day.selected {
  background-color: #8545b9;
  color: #fff;
  border-radius: 20px;
}

.calendar-day.selected:hover {
  background-color: #8545b9;
}

.calendar-weekdays {
  /* justify-content: space-between;
  background-color: #f0f0f0;
  padding: 8px 0;
  font-weight: bold;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px; */
  display: none;
}

.calendar-weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  background-color: #f0f0f0;
}
