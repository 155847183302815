@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@tailwind base;

@tailwind components;
@tailwind utilities;
#root {
  @apply relative min-h-screen h-full;
  /* padding-bottom: 260px; */
}
@media (max-width: 768px) {
  #root {
    /* padding-bottom: 421px; */
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @apply border border-primary-100;
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
}
@responsive {
  .text-small h3 {
    @apply text-base text-primary-100 !important;
  }
}

.web {
  @apply bg-white;
}

* {
  @apply m-0;
}
.body-container {
  @apply bg-white;
}

li {
  @apply mb-4;
}
/* 
.body-wrapper {
  min-height: calc(100vh - 356px);
  height: 100%;
  @apply bg-white;
} */
/* button {
  font-size: 16px;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  cursor: pointer;
} */
/* .bg-white {
  background-color: #fff;
} */

hr {
  @apply border border-boxline-200 my-5;
}

.vl {
  @apply border border-boxline-200 top-0 left-1/2 mr-5 mb-3;
  height: 100px;
}

.d-none {
  display: none;
}

.light-gray-bg {
  @apply bg-card-100;
  height: calc(100% - 266px); /*temp */
}
.smoke-white-bg {
  @apply bg-card-200;
}
/* DISABLED */
button:disabled {
  @apply hover:bg-transparent text-font-disabled bg-disabled hover:bg-disabled border border-disabled hover:border-disabled shadow-none;
  color: #aaa !important;
}
/* button:hover:disabled {
  @apply text-font-disabled hover:bg-disabled border border-disabled shadow-none;
} */

/*Content block */

/* Override style for some select packages start*/
.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.clear-selected-button {
  display: none !important;
}

/* Override style for some select packages end*/

.description-pills {
  background: #e5eeee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.description-text {
  font-size: 12px !important;
  font-weight: 450 !important;
  color: #313131 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-card {
  @apply py-6 px-6 lg:py-12 lg:px-14 border-t border-font-color;
}

.content-card p {
  @apply mt-3 mb-5;
}

/*Fix needed */
.authcenter-fix {
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .auth-wrapper {
    padding: 40px 0px;
  }
}

/*About page styles*/
.about-card {
  @apply mb-8 rounded-xl shadow-lg border border-card-200;
}
.about-card .header {
  @apply py-3 px-5 bg-card-200 rounded-t-xl;
}
.about-card .content {
  @apply lg:p-10 px-4 py-8 bg-white rounded-b-xl rounded-l-xl;
}

.list-kin {
  @apply my-5 rounded-xl shadow-lg border border-card-200;
}
@responsive {
  .list-kin-h3 {
    @apply text-primary-100 text-base !important;
  }
}

.list-kin-card-padding {
  @apply p-5 md:p-8;
}
@responsive {
  .list-kin-card-padding h3 {
    @apply text-font-dark text-base !important;
  }
}

.list-kin .header {
  @apply flex justify-between py-3 px-5 bg-card-200 rounded-t-xl rounded-r-xl;
}
@responsive {
  .react-datepicker-wrapper {
    @apply block !important;
  }
}
/* Each page content - one heading - few lines of description*/
.each-page-desc-wrap {
  @apply mt-20 mb-12 text-center;
}
.each-page-desc-wrap h3 {
  @apply uppercase mb-4 text-xl;
}
.each-page-desc-wrap p {
  @apply tracking-wider;
}

/* Trackers accordion style */

.tracker-acc-wrap {
  @apply block mb-4 bg-white border rounded-md;
}

.tracker-acc-head {
  @apply p-5 relative cursor-pointer font-semibold font-montserrat text-font-dark rounded-md;
}

.tracker-acc-head .tracker-acc-up-arrow {
  @apply absolute right-4	top-7	border-solid border-primary-100 border-r-2 border-b-2	inline-block p-1 bg-white;
}

.tracker-acc-head .tracker-acc-up-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.tracker-acc-head .tracker-acc-down-arrow {
  @apply absolute right-4	top-7	border-solid border-primary-100 border-r-2 border-b-2	inline-block p-1;
}

.tracker-acc-head .tracker-acc-down-arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.tracker-acc-content {
  @apply px-6 py-8 border-t border-boxline-100;
}

/*Graph CSS */
.graph-block {
  /* @apply border border-boxline-100; */
  height: 500px;
}

.graph-btn {
  @apply flex items-center justify-between;
}
/*Sleep tracker*/
.sleep-preview-blk {
  @apply block;
}
.sleep-preview-blk .total-time {
  @apply text-center text-sm tracking-wide;
}
.sleep-placeholder-preview-fill-bar {
  @apply my-3  border border-boxline-200 rounded-md w-1/2 relative bg-primary-100;
  height: 10px;
  left: 24%;
  bottom: 22px;
}
.sleep-preview-blk .sleep-placeholder-preview-bar {
  @apply w-full my-3 bg-card-200 border border-boxline-200 rounded-md;
  height: 10px;
}
.sleep-preview-blk .start-time p {
  @apply text-xs font-semibold;
}
.sleep-preview-blk .start-time p:last-child {
  @apply font-semibold;
}
.sleep-preview-blk .end-time p {
  @apply text-xs font-semibold;
}
.sleep-preview-blk .end-time p:last-child {
  @apply font-semibold;
}

/* Slider */
.slick-prev:before {
  @apply text-primary-100;
}
.slick-next:before {
  @apply text-primary-100;
}

/* End */
.sleep-archive-active {
  @apply border rounded-md border-primary-100 mb-6;
}
.btn-container {
  @apply absolute bottom-6 right-6;
}
.card-archive-container {
  @apply relative;
}
@responsive {
  .sleep-archive-active .sleep-pattern-card {
    @apply border-0 pb-6 mb-0 !important;
  }
  .sleep-archive-active .btn-container {
    @apply hidden !important;
  }
  .sleep-archive-active .h-8 {
    @apply hidden !important;
  }
}

.sleep-pattern-card {
  @apply p-6 border border-boxline-100 pb-16  mb-6 rounded-md;
}
.sleep-pattern-card.active {
  @apply border-2 border-primary-100;
}
.sleep-pattern-head {
  @apply text-base text-font-dark font-semibold;
}
.sleep-pattern-head span {
  @apply text-primary-100;
}
.archive-row p {
  @apply inline-block text-link text-base font-bold border-b border-link cursor-pointer leading-tight;
}
.archive-row a {
  @apply inline-block text-link text-base font-bold cursor-pointer leading-tight;
}
.sleep-graph-legend p {
  position: relative;
  padding-left: 22px;
}
.sleep-graph-legend p::before {
  @apply absolute left-0;
  content: '';
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.sleep-graph-legend .very-poor::before {
  @apply bg-mood-verypoorly;
}
.sleep-graph-legend .poor::before {
  @apply bg-mood-poor;
}
.sleep-graph-legend .alright::before {
  @apply bg-mood-alright;
}
.sleep-graph-legend .well::before {
  @apply bg-mood-well;
}
.sleep-graph-legend .very-well::before {
  @apply bg-mood-verywell;
}
.sleep-avg-wrap h3 {
  @apply text-base text-font-dark font-montserrat;
}
.sleep-avg-wrap h3 {
  @apply text-base;
}
@responsive {
  .card-head {
    @apply block text-base font-semibold !important;
  }
}
@responsive {
  .manage-alcohol-desc {
    @apply text-base font-normal tracking-wide text-font-dark !important;
  }
}
.manage-alcohol-container {
  @apply bg-primary-300 opacity-100;
}
.manage-alcohol-text {
  @apply text-white text-md font-bold;
}
.manage-alcohol-last span {
  @apply text-base text-primary-100 font-bold;
}
.date-field {
  /* background-image: url('../../assets/images/cal-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 10px center !important; */
}
/* .time-field {
  background-image: url('../../assets/images/time-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 10px center !important;
} */
/*input number fix*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.m-icon {
  @apply absolute right-2 text-primary-100;
  top: 38px;
}

.timeup-icon {
  @apply absolute right-1 px-1.5 cursor-pointer text-primary-100;
  top: 14px;
}
.timedown-icon {
  @apply absolute right-1 px-1.5 cursor-pointer text-primary-100;
  bottom: 14px;
}

.pass-wrapper {
  @apply relative flex;
  margin-bottom: 14px;
}

.toggle-pass-icon {
  @apply cursor-pointer absolute text-font-dark;
  top: 7px;
  right: 14px;
}

.hr-line {
  @apply w-full h-px bg-boxline-100;
}
/* Profile */
.profile-card {
  @apply md:container mx-auto bg-card-200;
}
.profile-card-wrap {
  @apply border border-boxline-100 rounded-md;
}
.profile-card-wrap .profile-card-top {
  @apply px-10 py-8 bg-primary-300;
}
.profile-card-wrap .profile-card-top .top-content {
  @apply flex align-middle justify-between;
  margin-left: 170px;
}
.profile-card-wrap .profile-card-bottom {
  @apply p-10 -mt-32 rounded-md;
}
.profile-card-wrap .profile-card-bottom .profile-img {
  @apply mb-12 bg-card-100 border border-boxline-100 rounded-md;
  width: 130px;
  height: 130px;
}

/* People */
.people-card {
  @apply md:container mx-auto bg-card-200;
}

/* Smoking */
.card-smoke {
  @apply p-3 border border-boxline-100 rounded-md;
}

/* Dashboard tab grey area container */
.dash-tab-grey {
  @apply mb-5 block border rounded-md;
}

.card-achieve {
  @apply p-1 border border-boxline-100;
}
.ilhTww {
  padding: 0px !important;
}
.injTBA {
  padding: 0px !important;
}

.card-face {
  @apply p-3 border border-boxline-100 flex items-center justify-center h-40 m-1;
}

.card-face img {
  @apply mx-auto;
}
.card-face p {
  @apply text-center font-bold text-sm;
}
.card-history {
  @apply p-3 border border-boxline-100;
}
.card-history span {
  @apply font-bold text-base text-primary-100;
}

.card-smoke-arrow {
  @apply text-primary-100 text-md font-bold mb-1.5 p-5 relative cursor-pointer inline-block;
}
.card-smoke-arrow span {
  @apply mr-5;
}

.card-smoke-arrow .tracker-acc-up-arrow {
  @apply absolute right-4	top-7	border-solid border-primary-100 border-r-2 border-b-2	inline-block p-1;
}

.card-smoke-arrow .tracker-acc-up-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.card-smoke-arrow .tracker-acc-down-arrow {
  @apply absolute right-6	top-5	border-solid border-primary-100 border-r-2 border-b-2	inline-block p-1;
}

.card-smoke-arrow .tracker-acc-down-arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.card-smoking {
  @apply p-6 border mb-6 border-boxline-100;
}
.card-smoking span {
  @apply text-primary-100 font-bold mr-2 inline-block;
}
.card-smoking p {
  @apply inline-block;
}

.right-border {
  @apply border-r border-boxline-100;
}

.chart-bg {
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  margin-top: -30px;
}

.heart-bg {
  /* background-image: url(../../assets/images/heart.svg); */
}
.wellbeing-bg {
  /* background-image: url(../../assets/images/wellbeing.svg); */
}
.money-bg {
  /* background-image: url(../../assets/images/money.svg); */
}
.card-rangestep {
  @apply px-7 py-10;
}

/*Alcohol tracker*/
@responsive {
  .drink-total p {
    @apply text-base font-semibold !important;
  }
}
@responsive {
  .drink-total.text-right p {
    @apply text-sm !important;
  }
}
.drink-total.text-right span {
  @apply text-base text-primary-100;
}
.alcohol-nhs-info {
  @apply text-sm;
}
@responsive {
  .alcohol-nhs-info a {
    @apply text-primary-100 text-sm !important;
  }
}

/* Calender */
@responsive {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    @apply rounded-full !important;
  }
}
@responsive {
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    @apply bg-primary-100 text-white font-bold !important;
  }
}

@responsive {
  .react-datepicker {
    @apply font-montserrat bg-white text-font-dark border-0 shadow-lg !important;
  }
}
@responsive {
  .react-datepicker__header {
    @apply bg-white  border-0 rounded-tl-none !important;
  }
}
@responsive {
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    @apply text-font-dark !important;
  }
}
@responsive {
  .react-datepicker__month-container {
    @apply border border-boxline-200;
  }
}
@responsive {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    @apply text-font-dark !important;
  }
}
@responsive {
  .react-datepicker__navigation-icon::before,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    @apply border-font-dark !important;
    top: 22px !important;
    border-width: 2px 2px 0 0 !important;
  }
}
@responsive {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    @apply m-2 !important;
  }
}
@responsive {
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: #a8a8a8 !important;
  }
}
@responsive {
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    @apply py-2 !important;
  }
}
@responsive {
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    @apply bg-primary-100 !important;
  }
}
@responsive {
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
  }
}
@responsive {
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae !important;
  }
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  @apply bg-card-200 h-8 p-2 outline-none;
}
@responsive {
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    @apply bg-primary-100 font-bold !important;
  }
}
@responsive {
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    @apply bg-transparent text-font-dark !important;
  }
}
@responsive {
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    left: -46px !important;
  }
}

/* Time picker */
@responsive {
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    @apply bg-primary-100 text-white font-bold !important;
  }
}
@responsive {
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:focus-visible {
    @apply bg-primary-300 font-bold !important;
  }
}
@responsive {
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    @apply hidden !important;
  }
}
@responsive {
  .react-datepicker-popper[data-placement^='bottom'] {
    @apply pt-0 !important;
  }
}
@responsive {
  .react-datepicker__time-container {
    width: 120px !important;
  }
}
@responsive {
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 120px !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    @apply bg-white;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    @apply bg-primary-100 text-button-primary !important;
  }
}

/* Dashboard styles*/
.dashboard-wrap {
  @apply bg-card-100 p-6 h-80;
}
.dash-greet-txt {
  @apply text-xl font-semibold;
}
.dash-card {
  @apply bg-white p-6 shadow-lg;
}

/* Problem tracker */
.problem-card {
  @apply relative border border-boxline-100 p-6;
  height: 480px;
}
.problem-card h3 {
  @apply text-font-dark font-montserrat text-base font-semibold;
}
.problem-card .btn-holder {
  @apply absolute bottom-4 left-4 right-4;
}
.problem-card .btn-holder p {
  @apply underline inline-block cursor-pointer font-bold;
  color: #ae6300;
}
.problem-status {
  @apply text-center text-base font-semibold;
}
.problem-status span {
  @apply text-center text-base text-primary-100;
}
.problem-date {
  @apply mt-1 text-center text-base;
}
/* Popover / tooltip designs */
.popup-over-content {
  @apply p-4 border border-boxline-100 absolute right-6 top-0 rounded-md bg-white;
  width: 270px;
  z-index: 999;
}
.popup-over-content p {
  @apply text-sm;
}
.popup-over-content a {
  @apply text-sm;
}

/**/
.sub-heading {
  @apply text-md font-semibold text-font-dark;
}
.share-text {
  @apply underline text-primary-100 text-base cursor-pointer font-bold;
}

/*Emoji*/
.emoji-wrap {
  @apply p-5 border border-input text-center rounded-md;
  font-family: 'Segoe UI Emoji';
}
.emoji-wrap .emoji-btn {
  @apply text-2xl;
}

/* Chart icon styles*/
.chart-overview-icon-wrap {
  @apply relative;
}
.chart-overview-icon {
  @apply absolute left-0 right-0 text-center;
  top: -115px;
}
.chart-overview-icon img {
  @apply inline-block mb-2 w-9;
}

.graph-block br {
  @apply hidden;
}
/*Apex chart style fixes*/
/* @responsive {
  .apexcharts-canvas {
    @apply w-full	!important;
  }
}
@responsive {
  .apexcharts-canvas svg {
    @apply w-full	!important;
  }
} */

textarea::placeholder {
  @apply text-font-dark;
}
@responsive {
  .Toastify__toast--success {
    @apply bg-primary-100 !important;
    text-align: left;
  }
}

/* Laoding */
.text-loading {
  font-family: 'Roboto', sans-serif;
}
.text-loading text {
  text-transform: uppercase;
  animation: stroke 4s infinite alternate;
  stroke-width: 1;
  stroke: #313131;
  font-size: 50px;
}
@keyframes stroke {
  0% {
    fill: rgba(49, 49, 49, 0);
    stroke: rgba(49, 49, 49, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 0.5;
  }
  70% {
    fill: rgba(49, 49, 49, 0);
    stroke: rgba(49, 49, 49, 1);
  }
  80% {
    fill: rgba(49, 49, 49, 0);
    stroke: rgba(49, 49, 49, 1);
    stroke-width: 1;
  }
  100% {
    fill: rgba(49, 49, 49, 1);
    stroke: rgba(49, 49, 49, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
/* Laoding End */

/* Directory */
.directory-wrap {
  @apply bg-card-200;
}
.directory-search {
  @apply px-8 py-12;
}
.directory-results {
  @apply px-10 py-20 bg-white;
}
.directory-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-screen-xl mx-auto;
}
.directory-filter-txt {
  @apply text-right mt-2 text-primary-100 underline text-sm cursor-pointer;
}
.directory-card {
  @apply border border-boxline-100 rounded-md;
}
.directory-top {
  @apply p-5 border-b border-boxline-100 flex;
}
.directory-top .profile {
  @apply border border-boxline-100 bg-card-100 rounded-md overflow-hidden;
  width: 68px;
  height: 68px;
}
.directory-top .name {
  @apply text-base font-bold;
}
.directory-top .email {
  @apply text-sm mb-1;
}
.directory-top .role {
  @apply inline-block mr-2 text-xs px-2 bg-primary-300 border border-primary-100 rounded-md;
}
.directory-middle {
  @apply p-5 overflow-y-auto;
  max-height: 200px;
}
.directory-middle .list {
  @apply mb-4;
}
.directory-middle .list:last-child {
  @apply mb-0;
}
.directory-middle .list p:first-child {
  @apply font-bold mb-1 text-sm;
}
.directory-middle .list p:last-child {
  @apply text-sm;
}
.directory-bottom {
  @apply p-5 text-right bg-primary-300;
}
.directory-bottom button:last-child {
  @apply ml-2 mt-2;
}

/*Admin dashboard - professional circle*/
.dash-tab-menus {
  @apply block p-2.5 pb-0 text-left bg-card-200 rounded-tl-md rounded-tr-md border-b border-boxline-100;
}
.dash-tab-menus .dash-tab-link {
  @apply inline-block px-4 py-2.5 text-primary-100 no-underline font-dosis tracking-wider;
}
.dash-tab-menus .dash-tab-link.active {
  @apply bg-white text-font-dark rounded-t-md border border-boxline-100 border-b-0;
}

/* Data grid styles */
.MuiDataGrid-windowContainer div {
  @apply text-font-dark;
}
.MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0px 16px !important;
}
.MuiDataGrid-columnHeaderTitle {
  @apply text-font-dark;
}
.MuiTablePagination-toolbar {
  @apply text-font-dark !important;
}
.MuiSelect-icon {
  @apply text-font-dark !important;
}
.MuiDataGrid-columnHeaderWrapper {
  @apply bg-primary-300;
}
.Mui-even.MuiDataGrid-row {
  @apply bg-white;
}
.Mui-odd.MuiDataGrid-row {
  @apply bg-card-200;
}

/* Myhealth */

.card-note {
  @apply p-5 shadow-md bg-white border border-card-200 rounded-md my-4;
}

/*Lifestyle card*/
.lifestyle-card {
  @apply block mb-4 bg-white border rounded-md relative;
}
.lifestyle-card.form {
  @apply col-start-1 col-end-3;
}
.lifestyle-card .card-top {
  @apply bg-card-200 p-4 text-base rounded-md;
}
.lifestyle-card .card-top p:first-child {
  @apply text-left font-bold;
}
.lifestyle-card .card-content {
  @apply px-4 py-6 border-t border-boxline-100 overflow-y-auto mb-14;
  height: 155px;
}
.lifestyle-card .card-content.extend {
  @apply h-auto mb-0;
}
.lifestyle-card .card-content h3 {
  @apply text-font-dark;
}
.lifestyle-card .card-content span {
  @apply text-base pl-1;
}
.lifestyle-card .card-content .list {
  @apply mb-4;
}
.lifestyle-card .card-content .list:last-child {
  @apply mb-0;
}
.lifestyle-card .card-content .measure-list {
  @apply grid grid-cols-2 mb-2;
}
.lifestyle-card .card-content .measure-list p:first-child {
  @apply text-base;
}
.lifestyle-card .card-content .measure-list p:last-child {
  @apply text-base text-right font-bold;
}
.lifestyle-card .card-content .list h4 {
  @apply text-base mb-1 text-font-dark;
}
.lifestyle-card .card-content .list p {
  @apply text-sm;
}
.lifestyle-card .card-bottom {
  @apply p-4 absolute bottom-0 left-0 right-0 bg-white rounded-b-md;
}
.lifestyle-card .card-bottom p {
  @apply text-sm text-primary-100 cursor-pointer;
}
/*SVG*/

@responsive {
  .sleep > #sleep_icon > rect,
  .smoke > #smoking_icon > rect,
  .mood > #mood_icon > rect,
  .pain > #pain_icon > rect,
  .problem > #problem_icon > rect,
  .alcohol > #alcohol_icon > rect,
  .blood > #blood_icon > rect,
  .bmi > #bmi_icon > rect,
  .temperature > #temperature_icon > rect {
    @apply fill-current text-primary-300 !important;
  }
  .sleep > #sleep_icon > #sleep > #Group_194 > path,
  .smoke > #smoking_icon > #smoke > #Group_198 > path,
  .smoke > #smoking_icon > #smoke > #Group_198 > rect,
  .alcohol > #alcohol_icon > #alcohol > path,
  .blood > #blood_icon > #blood > #Group_3681 > .blood-1 > path,
  .bmi > #bmi_icon > #Group_3678 > .bmi-1 > path,
  .mood > #mood_icon > #Group_3680 > .mood > path,
  .pain > #pain_icon > #Group_3682 > #Group_197 > path,
  .problem > #problem_icon > #problem > #Group_201 > path,
  .temperature > #temperature_icon > #Group_3684 > .temp-1 > path,
  .service > #service_icon > #Group_3655 > #Group_3653 > .service-2 > path,
  .service > #service_icon > #Group_3655 > #Group_3653 > .service-1 > path {
    @apply fill-current text-primary-100 !important;
  }
  .sleep > #sleep_icon > #sleep > #Group_195 > path,
  .smoke > #smoking_icon > #smoke > #Group_199 > path,
  .smoke > #smoking_icon > #smoke > #Group_199 > rect,
  .alcohol > #alcohol_icon > #alcohol > .al_secondary > path,
  .blood > #blood_icon > #blood > #Group_3681 > .blood-2 > path,
  .bmi > #bmi_icon > #Group_3678 > .bmi-2 > path,
  .mood > #mood_icon > #Group_3680 > path,
  .pain > #pain_icon > #Group_3682 > #Group_19 > path,
  .problem > #problem_icon > #problem > #Group_200 > path,
  .temperature > #temperature_icon > #Group_3684 > .temp-2 > path,
  .service > #service_icon > rect {
    @apply fill-current text-secondary-100 !important;
  }
}

.fa-minus{
  color: #f1c545 !important;
  cursor: pointer !important;
  margin-top: -12px !important;
}


.mar-st{
  margin-top: -5px;
}