.elgibilitypass-style {
    background-color: #99C355;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
}

.margin-tat-fo {
    margin-top: 40px;
}

.postGenital-style {
    background-color: #D35296;
    color: white;
    min-height: calc(100vh - 75px);
    ;
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.p-color {
    background-color: #D35296;
}

ul.br-order-list {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
}
ul.br-order-list > li:before {
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
}
.br-order-list li{
    font-size: 16px;
    font-family: 'MuseoSans', sans-serif;
    margin: 0;
}
.tfour-blk{
    width: 100%;
    min-width: 800px;
}