.grid-blk {
    @apply grid grid-cols-3 gap-20;   
}

.merge-blk {
    display: grid;
    justify-content: center;
    width: 65%;
}
.merge-blk .btn {
    padding: 8px 25px !important;
    height: 52px !important;
    border-radius: 10px !important;
  }
  .contact-attempt-popup .model-bor1 {
    border: none;
    border-radius: 20px !important;
  }

  .confirm-blk {
    display: grid;
    justify-content: center;
    margin: 20px 0px 20px 0px;  
}
  .confirm-blk .btn {
    padding: 8px 25px !important;
    height: 52px !important;
    border-radius: 10px !important;
  }
 .tick-flex {
  @apply flex items-center gap-4;
 }
.tick {
  display: flex;
  border: 2px solid #5A287F;
  border-radius: 20px;
  max-width: 140px;
  width: 125px;
  padding: 4px;
}