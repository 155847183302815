.link-style {
  color: #652f90 !important;
  text-decoration: underline;
  font-size: 18px;
  margin-right: 8px;
  /* line-break: anywhere; */
}

.link-style:hover {
  color: #652f90;
  text-decoration: underline;
}

.pad-btn {
  padding: 10px 77px 10px 66px;
}

.days-dropdown {
  width: 135px;
  height: 47px;
  background-color: white;
  border: 1px solid black;
}

.days-dropdown:focus-visible {
  border: 1px solid black !important;
}

.card-body-f-weight {
  font-weight: 700;
}

.card-body-f-weight p {
  font-size: 16px;
}

.remember-msg-color {
  color: #652f90;
}

.text-white {
  color: white !important;
}

.pregnancy-subpara-font {
  font-size: 43px;
  color: #008fbf;
  font-family: 'AlternateGothic', sans-serif;
  font-weight: 500 !important;
  letter-spacing: 0px;
}

.pregnancy-subpara-bg {
  background-color: #ffffff;
  width: 229px;
  height: 44px;
}

.react-datepicker {
  font-weight: 500 !important;
  box-shadow: none !important;
  border-radius: 0px 0px 6px 6px !important;
}

.react-datepicker__day--selected {
  background-color: #8545b9 !important;
  color: white !important;
}

.react-datepicker__current-month {
  background-color: #8545b9 !important;
  color: white !important;
}

.react-datepicker__header {
  padding-top: 0px !important;
}

.react-datepicker__navigation {
  top: -6px !important;
  color: #fff;
}

.react-datepicker__day--in-selecting-range {
  background-color: #8545b9 !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #9473af !important;
  color: white !important;
}

.react-datepicker__day--in-range {
  background-color: #8545b9 !important;
  color: white !important;
}

.img-adjust-pos-rel {
  position: relative;
}

.img-adjust-pos-rel .pos-abl {
  position: absolute;
  right: -40px;
  top: -30px;
}

@media (max-width: 768px) {
  .img-adjust-pos-rel .pos-abl {
    top: -20px;
  }
}

@media (max-width: 375px) {
  .img-adjust-pos-rel .dark {
    width: 100px;
  }
}

.pos-abs-arrow {
  position: absolute;
  left: 105px;
  top: 16px;
  width: 15px;
  pointer-events: none;
}

.pos-abs-arrow img {
  height: 18px;
}

.react-datepicker-time__header {
  display: none;
}

.react-datepicker-ignore-onclickoutside {
  width: 135px;
  padding-left: 14px;
  height: 47px;
  border: 1px solid black !important;
}

.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 50px;
  max-width: 135px;
  padding-left: 14px;
  border-color: #3c424f !important;
  cursor: pointer;
}

.react-datepicker__time {
  max-width: 145px;
  width: 148px;
}

.rmdp-header {
  background-color: #8545b9 !important;
  color: white !important;
}

@media (max-width: 370px) {
  .rmdp-calendar,
  .rmdp-top-class .rmdp-header {
    width: 195px !important;
  }

  .rmdp-day-picker {
    padding: 0 !important;
  }
}

.rmdp-day span:hover {
  background-color: #b280db !important;
}

.rmdp-calendar {
  padding: 0px !important;
  margin-top: -6px;
}

.rmdp-header-values {
  color: #fff !important;
}

.rmdp-selected .sd {
  background-color: #8545b9 !important;
  color: white !important;
}

.rmdp-selected .sd:hover {
  background-color: #8545b9 !important;
  color: white !important;
}

.rmdp-week-day {
  display: none !important;
}

.rmdp-arrow-container:hover {
  background-color: #8545b9 !important;
  color: #fff !important;
}

.rmdp-arrow-container {
  background-color: #8545b9 !important;
  color: #fff !important;
}

.rmdp-arrow {
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-day.rmdp-today span {
  background-color: #b280db !important;
}

.rmdp-arrow-container {
  margin: 1px 30px 0px 33px !important;
}

.rmdp-top-class .rmdp-header {
  /* width: 240px; */
  max-width: 240px;
}

.rmdp-day-picker > div {
  width: 230px;
  max-width: 230px;
}

.period-days {
  max-width: 200px;
  -webkit-appearance: inherit !important;
}

.period-days:focus {
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.span-color {
  color: #652f91;
}

.react-simple-star-rating-tooltip {
  background-color: #652f91 !important;
  color: #fff !important;
  display: table-cell !important;
  margin-top: 10px !important;
}

.filled-icons {
  color: #652f91 !important;
  display: flex !important;
}

.empty-icons {
  display: flex !important;
}

.f-weight-content p {
  font-weight: normal;
  font-size: 16px;
}

.bubble-desc-class {
  font-size: 16px;
  font-family: 'MuseoSans';
  color: black;
}

.bubble-context {
  color: #fff !important;
}

.service-class,
.service-class:hover,
.service-class:focus {
  text-decoration: none;
  color: black !important;
}

.ec-style {
  font-size: 16px;
}

.result-link {
  display: flex;
  justify-content: space-between;
}

.result-link img {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.rotate-arrow {
  transform: rotate(180deg);
}

.options-bor {
  border-radius: 10px;
  margin-top: -15px;
  width: auto !important;
}

.true-false-blk button {
  width: auto !important;
}

.options_btn_dropdown button {
  display: flex;
  align-items: center;
}

.options_btn_dropdown a:hover,
.hide_option a:hover {
  text-decoration: none;
}

.hide_option button {
  display: flex;
  align-items: center;
}

.hide_option img {
  transform: rotate(180deg);
}

.signposting-section .hide_option {
  text-align: center;
  margin-top: -20px;
}

.hide_option {
  text-align: -webkit-center !important;
}

.hide_option a {
  position: relative;
  top: -21px;
}

.primary-button-blk .understand-btn-dropdown {
  width: 65px;
}

.sure-btn {
  padding: 13px 37px 13px 40px !important;
}

.done-btn {
  padding: 13px 11px 13px 16px !important;
}

@media (max-width: 375px) {
  .m-desc-top {
    margin-top: 0px !important;
  }
}

@media (max-width: 510px) {
  .pink-btn-class {
    padding: 1px 10px !important;
  }
}

.contr-btn-blk {
  font-weight: 500;
}

@media (max-width: 450px) {
  .ec-header img {
    max-width: 144px !important;
    position: relative;
    left: -70px;
    top: -31px;
  }
}

@media (max-width: 768px) {
  .ec-header img {
    margin-left: 0px !important;
  }
}

@media (min-width: 560px) and (max-width: 660px) {
  .primary-button-blk span:nth-child(1),
  .iud-button-blk span:nth-child(1) {
    width: 100% !important;
    max-width: 483px !important;
  }
}

@media (max-width: 560px) {
  .primary-button-blk span:nth-child(1),
  .iud-button-blk span:nth-child(1) {
    width: 100% !important;
    max-width: 380px !important;
  }
}

@media (max-width: 500px) {
  .primary-button-blk span:nth-child(1),
  .iud-button-blk span:nth-child(1) {
    width: 100% !important;
    max-width: 290px !important;
  }
}

@media (max-width: 400px) {
  .primary-button-blk span:nth-child(1),
  .iud-button-blk span:nth-child(1) {
    width: 100% !important;
    max-width: 220px !important;
  }
}

@media (max-width: 660px) {
  .primary-button-blk {
    width: 100% !important;
    padding-left: 17px;
    padding-right: 0;
  }

  .iud-button-blk {
    width: 100% !important;
  }

  .primary-button-blk span:nth-child(2) {
    position: absolute;
    right: 17px;
  }

  .iud-button-blk span:nth-child(2) {
    position: absolute;
    right: 62px;
  }
}
/*calender css */
.calendar-container {
  width: 240px;
  font-size: 14px;
  font-family: 'MuseoSans', sans-serif;
  margin-left: 0;
  box-shadow: 0 0 5px #8798ad;
  border-radius: 5px;
}
.calendar-container button {
  margin-bottom: 0 !important;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background-color: #8545b9;
  color: #ffffff;
  font-weight: bold;
}

.calendar-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.calendar-month {
  margin: 0;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 8px;
  padding: 5px;
}
.calendar-grid button:focus-visible {
  outline-offset: 0px !important;
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin: auto;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
}
.calendar-day:disabled {
  border-width: 0px;
}
.calendar-day:hover {
  background-color: #8545b9;
  border-radius: 20px;
  color: #ffffff;
}
.calendar-day.empty {
  cursor: default;
}

.calendar-day.today {
  background-color: #8545b9;
  font-weight: bold;
  opacity: 0.8;
  color: #ffffff;
  border-radius: 20px;
}

.calendar-day:focus {
  outline: 2px solid #8545b9;
}

.calendar-day.selected {
  background-color: #8545b9;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 3px #8798ad;
}

.calendar-day.selected:hover {
  background-color: #8545b9;
  box-shadow: 0 0 3px #8798ad;
}

.calendar-weekdays {
  display: none;
}

.accordionBtn {
  @apply flex justify-between items-center w-full;
}

.arrowBtn svg {
  width: 20px;
}
.arrowBtn line {
  stroke: #000;
}
