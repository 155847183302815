.pos-pag {
  position: absolute;
  right: 48px;
  bottom: -8px;
}
.pagination > li > a,
.pagination > li > span {
  color: black !important;
}
.page-link:focus {
  outline: auto;
  box-shadow: none !important;
}
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #fff;
  color: #000 !important;
}
.pagination > li > span:hover {
  background-color: #673d91;
  color: white !important;
}

.pagination > li > a:hover{
  background-color: #ffff;
  color: black !important;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #652F91 !important;
  border-color: #652F91 !important;
}
/* 
.pre-nxt-color:active{
  color: #fff !important;
    background-color: #652F91 !important;
    border-color: #652F91 !important;
} */

/* .pre-nxt-color :hover{
  color: black !important;
  background-color: #fff !important;
} */