.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D6D5D5;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 20px;
width: 20px;
left: 2px;
bottom: 2px;
background-color: #AFAFAF;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #E3C7F8;
}

input:focus + .slider {
box-shadow: 0 0 1px #E3C7F8;
}

input:checked + .slider:before {
background-color: #944CC5;
-webkit-transform: translateX(21px);
-ms-transform: translateX(21px);
transform: translateX(21px);
}

.new_pointer:hover{
    cursor: pointer;
}
/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

.settings-list:hover{
pointer-events: none;
}