.selectgender-style {
  background-color: #30bdbf;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
}

.w-btn {
  width: 120px !important;
}

.btn-prefer {
  left: 295px !important;
}

.btn-le {
  left: 133px !important;
}

@media (min-width: 1025px) {
  .btn-ri {
    right: -247px !important;
  }
}

.btn-color {
  background-color: #68c7c8;
  border-color: #68c7c8 !important;
  color: black !important;
  font-weight: bold !important;
}

.btn-bgcolor {
  background-color: #81629b;
  color: white;
}

.btn-bgcolor:active:focus {
  outline: none !important;
}

.btn-cla {
  border: none;
  color: white !important;
  height: 50px;
  border-radius: 30px;
  font-size: 14px;
  font-family: museosans;
}
.btn-cla:focus-visible {
  color: #212529 !important;
}
.btn:hover {
  color: #212529 !important;
}
.merge-blk .btn:hover {
  color: #ffffff !important;
}

.f-b1 {
  font-size: 16px;
  padding-top: 20px;
}

.btn-color.selected {
  background-color: white;
}

@media (max-width: 480px) {
  .btn-prefer {
    top: 85px;
    left: 0 !important;
  }
  .f-b1 {
    padding-top: 95px !important;
  }
}

.text-sp {
  padding-bottom: 180px;
}

.internal-error-popup {
  cursor: pointer;
  min-height: 0px !important;
  max-width: 420px;
  position: relative;
}
.close-pop-up {
  position: absolute;
  top: 20px;
  right: 20px;
}
.f-size-contact {
  font-size: 18px !important;
}
.popup-info-section h1 {
  font-size: 2em; /* Typically around 32px */
  font-weight: bold; /* Equivalent to 700 */
}

.popup-info-section h2 {
  font-size: 1.5em; /* Typically around 24px */
  font-weight: bold;
}

.popup-info-section h3 {
  font-size: 1.17em; /* Typically around 18.72px */
  font-weight: bold;
}

.popup-info-section h4 {
  font-size: 1em; /* Typically around 16px */
  font-weight: bold;
}

.popup-info-section h5 {
  font-size: 0.83em; /* Typically around 13.28px */
  font-weight: bold;
}

.popup-info-section h6 {
  font-size: 0.67em; /* Typically around 10.72px */
  font-weight: bold;
}

.popup-info-section p {
  font-size: 0.9em; /* Typically 16px */
  font-weight: normal; /* 400 */
}
.popup-info-section a {
  display: inline-block;
  text-decoration: underline !important;
  color: #652f91 !important;
}
