.ft-tab {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  margin: 0px auto;
  padding: 10px 10px 30px;
  justify-content: space-between;
}

.postage-symptom-header {
  font-size: 22px;
  font-weight: 600;
}

.txt-highlight {
  font-size: 16px;
  font-weight: 600;
}

.anchor-btn {
  text-decoration: none !important;
  font-size: 18px;
  background-color: #fff;
  color: #333 !important;
  padding: 15px 20px;
  border-radius: 30px;
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 550px) {
  .brook-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 30px;
  }
}

@media (min-width: 550px) and (max-width: 768px) {
  .brook-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 30px;
  }
}

@media (min-width: 769px) {
  .brook-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 30px 0px;
  }
}

@media (min-width: 992px) {
  .brook-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 0px;
  }
}

@media (min-width: 1200px) {
  .brook-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 0px;
  }

  .form-width {
    max-width: 800px !important;
  }
}

.mb-px-100 {
  margin-bottom: 100px;
}

.jus-end {
  justify-content: flex-end;
}

.jus-center {
  justify-content: center;
}

.pad-bot {
  padding-bottom: 100px;
}

.text-sty {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: white;
}

.text-sty:hover {
  color: white;
}

.fo-style {
  padding-bottom: 5% !important;
  padding-top: 50px !important;
}

.fo-title {
  cursor: pointer;
  min-height: 0px !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.loader-bg {
  background-color: #f5f7f9;
}

.loader-brook {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  margin-left: 80px;
}

.loading-icon {
  height: 100%;
  min-height: 100%;
  display: flex;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  -ms-align-items: center;
  -webkit-justify-content: center;
  background-color: rgb(245, 247, 249, 0.8);
}

#brook_loader {
  animation: spin 1500ms linear infinite;
}

.pass-text-style {
  color: #8f949d;
  font-weight: 700;
  font-size: 18px;
}

/* login,signup, reset password input styles and common styles */

.admin-login-style {
  font-family: MuseoSans;
  display: flex;
  flex-direction: column;
}

.login-style1 {
  font-weight: 700 !important;
  font-size: 22px;
  margin-left: 3px;
}

.login-style2 {
  font-size: 37px;
  font-weight: 700;
}

.login-radius {
  border-radius: 50px;
  height: 60px;
  border: none;
  box-shadow: 0px 3px 6px #00000029 !important;
  margin-top: 12px;
  font-size: 22px;
}

.login-forget {
  color: #652f91;
  font-weight: 700;
  text-decoration: underline;
}

.login-btn-wrap {
  margin-top: 70px;
}

.login-btn {
  border-radius: 76px;
  font-size: 19px;
  padding: 10px 70px 10px 70px !important;
  background-color: #652f91 !important;
  color: white !important;
  border: none !important;
  font-weight: 700;
  text-decoration: none;
}

.login-btn:hover {
  background-color: #652f91 !important;
  color: white !important;
}

.login-btn:focus {
  background-color: #652f91 !important;
  color: white !important;
}

.in-style {
  font-size: 18px;
  padding: 0 !important;
}

.form-group input.form-control {
  padding: 16px 33px 16px 33px;
  color: black !important;
}

.popup-input .form-group input.form-control {
  padding: 16px;
}

.parent-pos {
  position: relative;
}

.icon-style {
  width: 32px;
}

.pos-arrow {
  position: absolute;
  top: 19px;
  left: 12px;
}

.i-icon,
.i-icon-login {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  background-color: #652f91;
  position: absolute;
  right: 0;
  top: 44px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.i-icon-signup {
  top: 97px !important;
}

.arrow-icon {
  height: 60px;
  width: 60px;
  background-color: #652f91;
  position: absolute;
  right: -2px;
  bottom: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  pointer-events: none;
}

/* Each page h1 heading style */

.page-heading {
  margin: 0px;
  font-family: 'AlternateGothic', sans-serif;
  font-size: 48px;
  text-align: left;
  /* text-transform: uppercase; */
  font-weight: 400;
  margin-top: 16px;
}

/* Primary button style changes */

.btn.btn-primary {
  padding: 13px 25px;
  width: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 26px;
  background-color: #652f91;
  border: none;
}

.btn.btn-primary:focus {
  background-color: #652f91 !important;
}

.btn.btn-primary.small {
  padding: 11px 20px;
  font-size: 14px;
  font-weight: 500;
}

.model-bor {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  cursor: default;
  box-shadow: 0 2px 3px 1px rgb(0 0 0 / 10%) !important;
}

@media (max-width: 768px) {
  .model-bor {
    overflow: auto;
  }
}

.body-pad {
  padding: 40px !important;
}

.modal-width {
  width: 100% !important;
}

@media (min-width: 576px) {
  .modal-width {
    max-width: 715px;
  }
}

@media screen and (min-width: 676px) {
  .modal-width-approve {
    max-width: 1000px !important;
    /* New width for default modal */
  }
}

.modal-width-approve {
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* Admin text area  */

.admin-textarea-style {
  border: none;
  border-radius: 6px;
  font-size: 22px;
  height: 165px !important;
  font-family: MuseoSans;
}

.admin-textarea-style:focus {
  box-shadow: none !important;
}

.sec-text-style {
  font-size: 18px;
  font-weight: 700;
  margin-top: 22px;
}

.pad-report {
  padding-top: 40px;
}

/*  signup dropdown */

.select-dropdown__control {
  height: 60px;
  border-radius: 50px !important;
  border-color: transparent !important;
}

.select-dropdown__input-container {
  height: 40px;
}

.select-dropdown__control--is-focused {
  border: none;
  box-shadow: none;
  border-width: 0px;
}

.select-dropdown__value-container {
  padding-left: 20px !important;
}

/* .select-dropdown__placeholder{
    padding-left: 15px;
} */

.select-dropdown__menu {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
  margin-top: -1px !important;
}

.select-dropdown__option {
  padding: 15px 26px !important;
  font-weight: 600;
  cursor: pointer;
}

.select-dropdown__option.select-dropdown__option--is-focused {
  background-color: #f7be00;
}

.select-dropdown__option.select-dropdown__option--is-focused:hover {
  background-color: #f7be00 !important;
}

.select-dropdown__option:hover {
  background-color: rgba(247, 190, 0, 0.1);
}

.select-dropdown__option:active {
  background-color: #f7be00 !important;
}

.select-dropdown__indicators {
  width: 100px;
}

.select-dropdown__dropdown-indicator {
  display: none !important;
}

/*  signup role dropdown */

.select-dropdown-role__control {
  height: 60px;
  border-radius: 50px !important;
  border-color: transparent !important;
  padding: 10px 0px 10px 0px;
  overflow: auto;
}

.css-1rhbuit-multiValue {
  margin-top: 4px !important;
}

.select-dropdown-role__input-container {
  height: 40px;
}

.select-dropdown-role__control--is-focused {
  border: none;
  box-shadow: none;
  border-width: 0px;
}

.select-dropdown-role__value-container {
  padding-left: 20px !important;
  margin-top: -6px;
}

/* .select-dropdown__placeholder{
    padding-left: 15px;
} */

.select-dropdown-role__menu {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
  margin-top: -1px !important;
}

.select-dropdown-role__option {
  padding: 15px 26px !important;
  font-weight: 600;
  cursor: pointer;
}

.select-dropdown-role__option.select-dropdown__option--is-focused {
  background-color: #f7be00;
}

.select-dropdown-role__option.select-dropdown__option--is-focused:hover {
  background-color: #f7be00 !important;
}

.select-dropdown-role__option:hover {
  background-color: rgba(247, 190, 0, 0.1);
}

.select-dropdown-role__option:active {
  background-color: #f7be00 !important;
}

.select-dropdown-role__indicators {
  width: 100px;
}

.select-dropdown-role__dropdown-indicator {
  display: none !important;
}

.select-dropdown__single-value {
  padding-top: 6px;
}

.errorMessage {
  display: block;
  padding-top: 10px;
  color: #ffc107;
}

.css-1pahdxg-control {
  box-shadow: none !important;
}

.pos-relative {
  position: relative;
}

.pos-admin {
  position: absolute;
  right: 0;
  bottom: 141px;
  top: -65px;
  min-height: 50px;
}

.badge-class {
  position: absolute;
  right: 18px;
  top: 14px;
  background-color: #e49b40;
}

@media (max-width: 768px) {
  .notification-icon-class {
    padding-right: 22px;
  }

  .badge-class {
    right: 32px;
  }
}

@media (max-width: 465px) {
  .notification-icon-class {
    padding-right: 28px;
  }
}

.archive-pop-title {
  font-size: 27px;
  font-weight: bold;
}

.true-border {
  border: 3px solid #fc601a;
}

.false-border {
  border: 3px solid #008fbf;
}

.chat-bubble-arrow {
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-bottom: 40px solid #fc601a;
  position: absolute;
  bottom: -23px;
  transform: rotate(104deg);
  left: 5px;
  z-index: 0;
}

.chat-blue {
  border-bottom: 40px solid #008fbf !important;
}

.chat-pink {
  border-bottom: 40px solid #c93488 !important;
}

.chat-green {
  border-bottom: 40px solid #329632 !important;
}

.myth-chat-bubble:before {
  content: '';
  width: 0;
  height: 0;
  border: 30px solid transparent;
  border-bottom: 38px solid #ffffff;
  position: absolute;
  bottom: -22px;
  transform: rotate(108deg);
  left: 7px;
}

.myth-chat-bubble {
  position: relative;
  text-align: left;
  padding: 30px 25px 25px 25px !important;
}

.s-body {
  z-index: 1;
}

.space-20 {
  padding: 20px;
}

.text-blue-myth {
  color: #008fbf !important;
}

.text-orange-myth {
  color: #fc601a !important;
}

.text-pink-myth {
  color: #c93488 !important;
}

.text-green-myth {
  color: #329632 !important;
}

.move-top {
  top: -11px !important;
}

@media (max-width: 767px) {
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    float: left;
    margin-right: 5px;
  }
}

.text-align-left {
  text-align: left;
}

/* Toggle Accordian */
.accordian-dropdown {
  position: fixed;
  top: 22px;
  right: 168px;
  width: 185px;
  max-width: 200px;
  cursor: pointer;
  z-index: 998;
}

.accordian-dropdown .sub-menu {
  flex-direction: column;
  padding: 20px;
}

.accordian-dropdown .switch {
  margin: 0;
}

.accordian-dropdown .toggle-style {
  margin-bottom: 10px;
}

.accordian-dropdown .header {
  font-size: smaller;
  font-weight: normal;
  margin-left: 16px;
  margin-right: 15px;
  padding-top: 6px;
  color: grey;
}

.accordian-dropdown .arrow-icon-accordian {
  height: 36px;
  width: 35px;
  background-color: #652f91;
  position: absolute;
  bottom: 2px;
  pointer-events: none;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  right: 0;
}

.accordian-dropdown .header {
  height: 38px;
}

.accordian-dropdown .collapse {
  background-color: #ffffff;
  box-shadow: 0 2px 3px 1px rgb(0 0 0/10%) !important;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.accordian-dropdown .card-w-h {
  /* position: fixed; */
  border-radius: 18px !important;
  height: 36px;
  border: none;
  box-shadow: 0 2px 3px 1px rgb(0 0 0/10%) !important;
}

.tri-nav-blk a:hover {
  color: black !important;
}

.arrow-pos {
  position: absolute;
  width: 26px;
  top: 16px;
  left: 8px;
}

.disable {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.no-notification {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactbrook-h {
  min-height: 100vh !important;
  font-size: 16px !important;
}

.lo-pos {
  top: 11px !important;
  left: 5px !important;
}

.what-next-sub-content {
  font-family: museosans;
  font-size: 18px;
  padding: 50px 0px 10px 0px;
}

/* Exit footer */
.exit-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #8cc63f;
  line-height: 2;
  text-align: center;
  color: #ffff;
  font-size: 30px;
  font-weight: bold;
  font-size: 37px;
  z-index: 1;
}

/* STI sigmposting */
/* .answer a {
      color: #3a62fe !important;
  } */

/* .answer a:hover{
    color: #000 !important;
}

.answer a:focus {
    color: #000 !important;
} */

.css-1s2u09g-control {
  box-shadow: 0px 3px 6px #00000029;
}

/* Answer css for dilemma  */

.view-answer {
  font-size: 30px !important;
  font-weight: 200;
  font-family: 'AlternateGothic', sans-serif;
}

.list-pass {
  list-style: inside;
}

.list-pass p {
  font-size: 18px;
  font-weight: 700;
  color: #8f949e;
}

input[type='checkbox']:focus {
  outline: none !important;
}

/* button:focus-visible,
select:focus-visible, */
.btn:active:focus {
  outline: none !important;
}

.badge-message {
  position: absolute;
  top: 12px;
  right: 15px;
  background-color: #e51c1c !important;
}

.remove-opacity {
  opacity: 3 !important;
}
