.successpostcode-style {
    background-color: #99C355;
    color: white;
    min-height: calc(100vh - 75px);
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.g-color {
    background-color: #99C355;
}

.s-pad {
    padding-bottom: 118px !important;
}