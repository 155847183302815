.symptombarrier-style {
  background-color: #d35296;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.callyou-style {
  background-color: #d35296;
  color: white;
  min-height: calc(100vh - 75px);
  font-family: 'AlternateGothic', sans-serif;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

@media (min-width: 500px) {
  .callyou-style {
    min-height: 100vh !important;
  }
}

.g-class {
  background-color: #d96aa4;
  border-color: #d96aa4;
}

.mar-style {
  margin-top: 19px;
}

.p6-style {
  padding-top: 82px;
}

.b-style {
  font-size: 18px;
  font-family: museosans;
  line-height: normal;
}

.text-a-tag {
  text-decoration: underline;
}

.text-a-tag:hover {
  color: white;
}

.text-a-tag:focus {
  color: white;
}

.under16-style .b1-style {
  letter-spacing: 0.2px;
  padding: 50px 0px 10px 0px;
  font-size: 18px;
  font-family: museosans;
  line-height: 30px;
}

.under16-style .b1-style p {
  padding-top: 20px;
}

.bullet-list {
  list-style: inherit;
}

.consent-accordian .plus-i {
  margin-top: 8px !important;
}

.consent-accordian {
  cursor: pointer;
}

.consent-accordian .pad-te {
  padding-top: 0 !important;
}
