.services-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.services-list .input-group-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}
.services-list .input-group-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  height: 66px;
  font-size: 18px;
  line-height: 23px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  cursor: pointer;
}
.services-list .input-group-text label {
  margin: 0px;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
}
.services-label-img {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}

@media (max-width: 568px) {
  .services-list .input-group-text {
    display: grid;
    flex-direction: row;
    align-items: flex-start;
    white-space: normal;
    min-height: 150px;
  }
  .services-checkmark {
    top: 63px !important;
  }
  .services-label-img {
    margin: auto;
  }
  .services-btn {
    position: relative !important;
    min-height: 51px;
  }
}

/* The container */
.input-group-text {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input-group-text:hover {
  border: 1px solid #663290;
  background-color: #f0f4fe;
}

/* Hide the browser's default radio button */
.input-group-text input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.services-checkmark {
  position: absolute;
  top: 21px;
  left: -11px;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #cdcdcd;
}

/* On mouse-over, add a grey background color */
.input-group-text:hover input ~ .services-checkmark {
  border: 1px solid #663290;
}

/* When the radio button is checked, add a blue background */
.input-group-text input:checked ~ .services-checkmark {
  background-color: #fff;
  border: 1px solid #663290;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.services-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.input-group-text input:checked ~ .services-checkmark:after {
  display: block;
}
.input-group-text input:checked ~ .services-btn {
  display: inline-block;
}

/* Style the indicator (dot/circle) */
.input-group-text .services-checkmark:after {
  top: 3px;
  left: 3px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #663290;
}

.services-btn {
  /* display: none; */
  position: absolute;
  right: 8px;
  width: auto;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 13px 35px;
  border-radius: 50px;
  background-color: #663290;
  border: 1px solid #663290;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.services-btn:hover {
  background-color: #fff;
  color: #0c2f2f;
  border-color: #663290;
  text-decoration: none;
}
.services-btn:active,
.services-btn:focus {
  color: #fff;
  background-color: #663290;
  border: 1px solid #663290;
  text-decoration: none;
}
.helpline-heading p:first-child,
.helpline-heading a {
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-decoration: underline !important;
  color: #0c2f2f;
}

.helpline-heading a:hover {
  color: #0c2f2f;
}

.mw-switch-desktop {
  background-color: #ffffff !important;
}

.sub-helpline-links {
  color: #652f91 !important;
}

.sub-helpline-links:hover {
  text-decoration: none !important;
}
