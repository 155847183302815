.table-section .rdt_Table {
  min-height: 300px;
}

.table-section .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 18px !important;
  font-weight: 600;
  color: #3B4250;
}

.table-section .rdt_TableCell {
  font-size: 16px !important;
  color: #3B4250;
}

/* .table-section .manager-blk .rdt_TableHeadRow :nth-child(2),
.table-section .users-blk .rdt_TableHeadRow :nth-child(2) {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-right: 0 !important;
} */

.table-section .rdt_Table .rdt_TableHead .rdt_TableHeadRow :nth-child(2)>.rdt_TableCol_Sortable {
  overflow: visible !important
}

/* ul.Cascade-module_dropdownMenu__19WmY.customdropdownMenu.customdropdownMenu0.show {
  animation: fadeInShow .3s;
  display: block;
} */

.dropdown-cascade-blk input {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  opacity: 0;
}

/************************************************
        custom check box on row select
*************************************************/
.custom-checkbox {
  position: relative;
}

.check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  height: 25px;
  width: 25px;
  background-color: #FFF;
  border-radius: 5px;
  border: 1px solid #757778;
}

/* When the checkbox is checked, add a white background */
.check-label input:checked+.checkmark {
  background-color: #FFF;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-label input:checked+.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-label .checkmark::after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: 1px solid #652F91;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/************************************************
        End of custom check box on row select
*************************************************/