.navbar_text {
  font-size: 1.375rem;
  margin: 0;
}

.navbar_background {
  background-color: #eff4f6;
}

.logo-img > img {
  width: 30px;
  height: 30px;
}