.postcode-style {
    background-color: #582e7a;
    color: white;
    min-height: calc(100vh - 75px);
    font-family: 'AlternateGothic', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.v-color {
    background-color: #582e7a;
}

.page-header-txt {
    font-size: 29px;
    font-family: 'AlternateGothic', sans-serif;
}

.input-checkbox-style {
    max-width: 50px;
    height: 50px;
    margin-top: 30px;
    font-size: 17px;
    font-family: museosans;
    letter-spacing: 0.28px;
}

.input-style {
    font-size: 17px;
    font-family: museosans;
    letter-spacing: 0.28px;
    margin: 5px 0px;
    padding: 25px 10px;
    border-radius: 5px;
}

.btn-style-foot {
    padding-top: 237px;
}