.input-group {
  @apply relative;
}
.input-group label,
.dropdown-label {
  @apply text-sm block mb-3 font-bold text-left tracking-wide text-font-dark;
}
@responsive {
  .input-group .text-field:disabled::placeholder {
    @apply text-font-placeholder;
  }
}

.input-group .text-field:disabled {
  @apply bg-disabled;
}
.input-group .text-field,
.dropDown {
  @apply border py-2 px-4 border-input relative bg-transparent text-sm tracking-wide rounded text-font-dark;
  box-sizing: border-box;
  letter-spacing: 1px;
}
.input-group .text-field:focus {
  @apply outline-none border border-primary-100;
}
.input-group .text-field::placeholder {
  @apply font-normal text-font-placeholder;
}
.input-group .text-field[type='date'] {
  @apply uppercase;
}
.input-group .w-100,
.w-dropdown-100 {
  @apply w-full;
}
.form-sm-txt {
  @apply mt-1 text-sm;
}
.form-sm-txt.left {
  @apply text-left;
}
.form-sm-txt.center {
  @apply text-center;
}
.form-sm-txt a {
  @apply ml-1 text-sm no-underline;
}
.form-error-msg {
  @apply mt-2 text-left text-sm text-error-100;
}
.input-group .text-field.input-error {
  @apply border border-error-100;
}
